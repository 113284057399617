import React from "react";
import { CardContent, CardMedia } from "@mui/material";
import "./ItemCard.css";

const ItemCard = ({ item }) => {
  return (
    <div className="cart-wrapper">
      <CardMedia className="card-media" image={item.image} title={item.name} />
      <CardContent className="card-content">
        <h5>{item.name}</h5>
        <p>Размер: {item.size}</p>
      </CardContent>
    </div>
  );
};

export default ItemCard;
