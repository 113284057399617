import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "filter-accordion-wrapper": {
    backgroundColor: "#f1f1f1 !important",
    color: "#black !important",
    boxShadow: "0 0 5px 2px rgba(0, 0, 0, 0.1) !important",

    "& .filterBox": {
      boxShadow: "none !important",
    },
  },
  "filter-accordion-icon": {
    color: "black !important",
    width: "30px !important",
    height: "30px !important",
  },

  "filter-accordion-details": {
    padding: "0 !important",
  },
}));
