import React, { useEffect } from "react";
import "./Products.css";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import { useRouteMatch } from "react-router-dom";
import MetaData from "../layouts/MataData/MataData";
import { clearErrors, getProduct } from "../../actions/productAction";
import ProductCard from "../Home/ProductCard";
import Pagination from "react-js-pagination";
import Slider from "@mui/material/Slider";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import InventoryIcon from "@mui/icons-material/Inventory";
import { useParams } from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useTheme } from "@emotion/react";
import { categories } from "../../constants/productsConstatns";
import {
  FaAngleDoubleLeft,
  FaAngleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
  FaBan,
} from "react-icons/fa";
import HomeCard from "../Home/HomeCard";
import { getCloakedFromCookie } from "../../reducers/productReducers";
import { debounce } from "../../utils/utils";
import FilterAccordion from "../../collections/Accordion/FilterAccordion";
import { ImageGridSkeleton } from "../../collections/Sekeletons/ImageGridSkeleton";

function Products() {
  const match = useRouteMatch();
  let keyword = match.params.keyword;
  const dispatch = useDispatch();
  const {
    products,
    loading,
    productsCount,
    filteredProductCount,
    error,
    resultPerPage,
  } = useSelector((state) => state.products);
  const productState = useSelector((state) => state.products);
  const alert = useAlert();
  const { categoryHeader } = useParams();
  const [currentPage, setCurrentPage] = React.useState();
  const [price, setPrice] = React.useState([0, 400]); // initial limit from min=0 to max=100000
  const [category, setCategory] = React.useState(categoryHeader || "");
  const [ratings, setRatings] = React.useState(0);
  const [selectedCategory, setSelectedCategory] = React.useState("");
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(768));
  // const { cloaked } = useSelector((state) => state.products);
  const showMobileFilter = useMediaQuery(theme.breakpoints.down(1050));
  const [open, setOpen] = React.useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedGetProducts = React.useCallback(
    debounce((price) => {
      dispatch(getProduct(keyword, currentPage, price, category, ratings));
      if (showMobileFilter) {
        setOpen(false);
      }
    }, 1000),
    [dispatch, keyword, currentPage, category, ratings]
  );

  const priceHandler = (event, newPrice) => {
    setPrice(newPrice);
    debouncedGetProducts(newPrice);
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getProduct(keyword, currentPage, price, category, ratings));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, keyword, currentPage, ratings, category, categoryHeader]);

  const setCurrentPageNoHandler = (e) => {
    setCurrentPage(e); // e is the clicked page value
  };

  const handleCategoryChange = (category) => {
    // window.fbq('trackCustom', 'SelectedCategory', {
    //   search_category: category,
    // });

    setCategory(category);
    setSelectedCategory(category);
    // Perform any additional actions or filtering based on the selected category
  };
  // const [selectedRating, setSelectedRating] = React.useState("all");

  // const handleRatingChange = (event) => {
  //   setRatings(event.target.value);
  //   setSelectedRating(event.target.value);
  //   // Trigger filtering with the selected rating value or perform any other action

  // };

  useEffect(() => {
    if (categoryHeader) {
      handleCategoryChange(categoryHeader);
    }
  }, [categoryHeader]);

  const showPagination = filteredProductCount > 15;

  return (
    <main className="">
      <MetaData title="Shop" />
      <div>
        <div
          className="productPage"
          style={isMobile ? { flexDirection: "column" } : {}}>
          {!showMobileFilter ? (
            <Filter
              handleCategoryChange={handleCategoryChange}
              debouncedGetProducts={debouncedGetProducts}
              price={price}
              priceHandler={priceHandler}
              selectedCategory={selectedCategory}
              setPrice={setPrice}
              setOpen={setOpen}
            />
          ) : (
            <FilterAccordion
              label="Filter"
              className={"filterAccordion"}
              open={open}
              setOpen={setOpen}>
              <Filter
                debouncedGetProducts={debouncedGetProducts}
                setOpen={setOpen}
                open={open}
                showTitle={false}
                handleCategoryChange={handleCategoryChange}
                price={price}
                priceHandler={priceHandler}
                selectedCategory={selectedCategory}
                setPrice={setPrice}
              />
            </FilterAccordion>
          )}
          {loading ? (
            <ImageGridSkeleton className="homeCardContainer" />
          ) : (
            <div className="homeCardContainer">
              {!products.length ? (
                <NoProductsCaption />
              ) : (
                products.map((product, i) => (
                  <HomeCard
                    key={`image-grid-${product._id}-${i}`}
                    product={product}
                  />
                ))
              )}
            </div>
          )}
        </div>

        {/* Pagination */}
        {showPagination && !loading && (
          <div className="paginationBox">
            <Pagination
              activePage={currentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={filteredProductCount}
              onChange={setCurrentPageNoHandler}
              nextPageText={<FaAngleRight />}
              prevPageText={<FaAngleLeft />}
              firstPageText={<FaAngleDoubleLeft />}
              lastPageText={<FaAngleDoubleRight />}
              itemClass="page-item"
              linkClass="page-link"
              activeClass="pageItemActive"
              pageRangeDisplayed={isMobile ? 4 : 5}
              hideDisabled={true}
              // hideNavigation={isMobile}
              // hideFirstLastPages={isMobile}
              activeLinkClass="pageLinkActive"
            />
          </div>
        )}
      </div>
      {loading && <Loader />}
    </main>
  );
}

const useStylesNoProd = makeStyles((theme) => ({
  wrapper: {
    padding: "40px 0",
    gridColumn: "span 4",
  },
  title: {
    fontSize: "1.5rem",
    fontWeight: 700,
    color: "#0000006e",
  },
  icon: {
    fontSize: "4rem",
    color: "#0000006e",
    margin: "0 auto",
    display: "block",
  },
}));

const NoProductsCaption = () => {
  const classes = useStylesNoProd();

  return (
    <div className={classes.wrapper}>
      <FaBan className={classes.icon} />
      <h2 className={classes.title}>Няма намерени продукти...</h2>
    </div>
  );
};

const Filter = ({
  open,
  setOpen,
  price,
  priceHandler,
  setPrice,
  selectedCategory,
  handleCategoryChange,
  debouncedGetProducts,
  className,
  showTitle = true,
}) => {
  return (
    <div className={`productPageTop ${className}`}>
      <div className="filterBox">
        {/* Price */}
        {showTitle && (
          <Typography
            style={{
              fontSize: "25px",
              padding: "5px",
              fontWeight: 700,
              color: "black",
            }}>
            Филтър
          </Typography>
        )}
        <br />
        <div className="priceFilter">
          <Typography
            style={{
              fontSize: "18px",
              padding: "5px",
              fontWeight: 700,
              color: "black",
            }}>
            Цена
          </Typography>
          <div className="priceSlider">
            <Slider
              className="price-slider-component"
              value={price}
              onChange={priceHandler}
              min={0}
              max={400}
              step={10}
              valueLabelDisplay="auto"
              aria-labelledby="range-slider"
            />
          </div>
          <div className="priceSelectors">
            <div className="priceSelector">
              <Select
                value={price[0]}
                onChange={(e) => {
                  const newPrice = [+e.target.value, price[1]];
                  setPrice(newPrice);
                  debouncedGetProducts(newPrice);
                }}
                className="priceOption"
                IconComponent={ArrowDropDownIcon}
                renderValue={(selected) =>
                  selected !== "" ? selected : "min"
                }>
                <MenuItem value={130} className="menu_item">
                  130
                </MenuItem>
                <br />
                <MenuItem value={170} className="menu_item">
                  170
                </MenuItem>
                <br />
                <MenuItem value={190} className="menu_item">
                  190
                </MenuItem>
                <br />
                <MenuItem value={210} className="menu_item">
                  210
                </MenuItem>
              </Select>
              <span className="toText">to</span>
              <Select
                value={price[1]}
                onChange={(e) => {
                  const newPrice = [price[0], +e.target.value];
                  setPrice(newPrice);
                  debouncedGetProducts(newPrice); // Add this line
                }}
                className="priceOption"
                IconComponent={ArrowDropDownIcon}
                renderValue={(selected) =>
                  selected !== "" ? selected : "max"
                }>
                <MenuItem value={160} className="menu_item">
                  160
                </MenuItem>
                <br />
                <MenuItem value={180} className="menu_item">
                  180
                </MenuItem>
                <br />
                <MenuItem value={200} className="menu_item">
                  200
                </MenuItem>
                <br />
                <br />
                <MenuItem value={220} className="menu_item">
                  220
                </MenuItem>
                <br />
                <MenuItem value={240} className="menu_item">
                  240
                </MenuItem>
              </Select>
            </div>
          </div>
        </div>

        <div className="filter_divider"></div>
        {/* Categories */}
        {/* <DropdownMenu
                categories={categories}
                selectedCategory={selectedCategory}
                handleCategoryChange={handleCategoryChange}
              /> */}
        <div className="category-wrapper">
          <h4>Категории</h4>
          {categories.map((category, index) => (
            <FormControlLabel
              className="category-checkbox-wrapper"
              key={`categories-checkbox-${index}`}
              control={
                <Checkbox
                  className="category-checkbox"
                  checked={selectedCategory === category}
                  onChange={() => {
                    if (selectedCategory === category) {
                      handleCategoryChange("");
                    } else {
                      handleCategoryChange(category);
                    }
                    setOpen(!open);
                  }}
                  name={category}
                />
              }
              label={`Обувки ${category}`}
            />
          ))}
        </div>
        {/* <div className="categoriesFilter">
                    <Typography
                      style={{
                        fontSize: "18px",
                        padding: "10px",
                        fontWeight: 700,
                        color: "#414141",
                      }}
                    >
                      Categories
                    </Typography>
                    <ul className="categoryBox">
                      {categories.map((category, index) => (
                        <li className="category-link" key={index}>
                          <label
                            htmlFor={`category-${index}`}
                            className="category-label"
                          >
                            <input
                              type="checkbox"
                              id={`category-${index}`}
                              className="category-checkbox"
                              value={category}
                              checked={category === selectedCategory}
                              onChange={() => handleCategoryChange(category)}
                            />
                            Обувки {category}
                          </label>
                        </li>
                      ))}
                    </ul>
                  </div> */}
        {/* Ratings */}
        {/* <div className="ratingsFilter">
                    <Typography
                      style={{
                        fontSize: "18px",
                        padding: "10px",
                        fontWeight: 700,
                        color: "#414141",
                      }}
                    >
                      Ratings Above
                    </Typography>
                    <RadioGroup
                      value={selectedRating}
                      onChange={handleRatingChange}
                      row
                      className="ratingsBox"
                    >
                      <FormControlLabel
                        value="4"
                        control={<Radio />}
                        label="4★ & above"
                      />
                      <FormControlLabel
                        value="3"
                        control={<Radio />}
                        label="3★ & above"
                      />
                      <FormControlLabel
                        value="2"
                        control={<Radio />}
                        label="2★ & above"
                      />
                    </RadioGroup>
                  </div>
                  <div className="filter_divider"></div> */}
      </div>
      {/* Clear Filters */}
    </div>
  );
};

export default Products;
