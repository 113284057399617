import React from "react";
import { TextField } from "@material-ui/core";
import { useStyles } from "./styles";

const TextInput = ({
  register,
  name,
  label,
  errors,
  type = "text",
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <TextField
        {...register(name)}
        label={label}
        variant="outlined"
        type={type}
        fullWidth
        className={`${classes.outlinedInput} ${
          errors[name] && classes["error-outlinedInput"]
        }`}
        {...props}
      />
      {errors[name] && (
        <span className={classes["error-message"]}>{errors[name].message}</span>
      )}
    </>
  );
};

export default TextInput;
