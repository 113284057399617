import {
  ADD_TO_CART,
  REMOVE_CART_ITEM,
  SAVE_SHIPPING_INFO,
} from "../constants/cartConstant";

export const getProductDetails = async (id) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/api/v1/product/${id}`,
      {
        method: "GET",
        credentials: "include", // Include credentials (cookies, HTTP authentication)
      }
    );

    if (!response?.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

// Add to Cart
export const addItemToCart = (id, size, add) => async (dispatch, getState) => {
  const data = await getProductDetails(id);
  function changeItemQty() {
    const currentQty = getProductQuantityFromLocalStorage(
      data.Product._id,
      size
    );
    const newQty = add ? currentQty + 1 : currentQty - 1;

    if (!data.Product.Stock[size]) {
      return 0;
    }
    if (newQty < 1 || newQty > data.Product.Stock[size]) {
      return currentQty;
    }
    return newQty;
  }

  dispatch({
    type: ADD_TO_CART,
    payload: {
      productId: data.Product._id,
      name: data.Product.name,
      price: data.Product.price,
      oldPrice: data.Product.oldPrice,
      image: data.Product.images[0].url,
      stock: data.Product.Stock,
      size,
      quantity: changeItemQty(),
    },
  });

  // Save cart data to localStorage after dispatching the action
  localStorage.setItem("cartItem", JSON.stringify(getState().cart.cartItems));
};

// Remove item from Cart
export const removeItemFromCart = (id, size) => async (dispatch, getState) => {
  dispatch({ type: REMOVE_CART_ITEM, payload: { id, size } });
  // Save cart data to localStorage after dispatching the action
  localStorage.setItem("cartItem", JSON.stringify(getState().cart.cartItems));
};

// Save Shipping Info
export const saveShippingInfo = (data) => async (dispatch, getState) => {
  dispatch({
    type: SAVE_SHIPPING_INFO,
    payload: data,
  });

  // Save shipping info data to localStorage after dispatching the action
  localStorage.setItem("shippingInfo", JSON.stringify(data));
};

const getProductQuantityFromLocalStorage = (productId, size) => {
  // Retrieve the cart items from localStorage
  const cartItems = JSON.parse(localStorage.getItem("cartItem"));

  // Check if cartItems exists and is an array
  if (!cartItems || !Array.isArray(cartItems)) {
    return 0;
  }

  // Find the specific product in the cart
  const product = cartItems.find(
    (item) => item.productId === productId && item.size === size
  );

  // Return the quantity if the product is found, otherwise return 0
  return product ? product.quantity : 0;
};
