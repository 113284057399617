import React from "react";
import { ReactComponent as CricketBall } from "../../../Image/Loader-svg/LoaderBlack.svg";
import "./Loader.css";

const CricketBallLoader = ({ badConnection }) => {
  React.useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  return (
    <div className="cricket-ball-loader">
      <CricketBall className="spinner" />
      {badConnection && (
        <h2 style={{ textAlign: "center" }}>Check your network connection.</h2>
      )}
    </div>
  );
};

export default CricketBallLoader;
