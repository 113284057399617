import React from "react";
import CartTableRow from "./Row/CartTableRow";
import "./CartTable.css";

export default function CartTable({ items }) {
  return (
    <section className="cart-table">
      <div className="cart-table-header">
        <div className="card-table-item-col">Продукт</div>
        <div className="card-table-price-col">Цена</div>
        <div className="card-table-quantity-col">Брой</div>
        <div className="card-table-total-col">Общо</div>
      </div>
      {items.map((item, i) => (
        <CartTableRow
          item={item}
          key={`cart-table-row-${i}-${item.name}}-${item.productId}-${item.size}`}
        />
      ))}
    </section>
  );
}
