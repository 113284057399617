import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./SliderComponent.css";
import { af1, aj1, aj4, scorpion, yeezy1 } from "./images/img";
import { shuffleArray } from "../../../constants/homeConstants";
import { categories } from "../../../constants/productsConstatns";
import ProductSlider from "./ProductSlider";
import { useDispatch, useSelector } from "react-redux";
import {
  getLatestProducts,
  getShuffledProducts,
} from "../../../actions/productAction";

const sliderData = [
  {
    name: "AF1",
    image: af1,
    url: `/${categories[4]}`,
  },
  {
    name: " AJ4",
    image: aj4,
    url: `/${categories[8]}`,
  },
  {
    name: " AJ1",
    image: aj1,
    url: `/${categories[9]}`,
  },
  {
    name: "NK Scorpion",
    image: scorpion,
    url: `/${categories[2]}`,
  },
  {
    name: "Yeezy Boost ",
    image: yeezy1,
    url: `/${categories[0]}`,
  },
];

const SliderComponent = () => {
  const dispatch = useDispatch();
  const { shuffledProducts } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getShuffledProducts());
  }, [dispatch]);

  return (
    <div className="slider-container">
      <h1>Най-известни модели</h1>
      <ProductSlider>
        {shuffledProducts &&
          shuffleArray(shuffledProducts).map((item, index) => (
            <div key={index} className="slider-item">
              <a href={`/product/${item._id}`} rel="noopener noreferrer">
                <img src={item.images[1].url} alt={item.name} />
                <h3 className="trim-text">{item.name.split("Обувки")[1]}</h3>
              </a>
            </div>
          ))}
      </ProductSlider>
    </div>
  );
};

export default SliderComponent;
