import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { myOrders, clearErrors } from "../../actions/orderAction";
import MetaData from "../layouts/MataData/MataData";
import CricketBallLoader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import OrderCard from "./OrderCard";
import { ShoppingCartRounded } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  orderSection: {
    width: "100%",
    maxWidth: "1440px",
    margin: "100px auto 0",
    padding: "0 40px 40px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    [theme.breakpoints.down(768)]: {
      padding: "0 20px 20px",
    },
  },

  orderPageContainer: {
    backgroundColor: "#fff",
    boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  orderPageTitle: {
    fontSize: "1.2rem",
    fontWeight: "bold",
  },
  orderPageText: {
    color: "#6c757d",
    marginTop: "1rem",
  },
  noOrdersCaptionWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    height: "55vh",
    fontSize: "1.5rem",
    color: "#a8afb4",

    "& svg": {
      fontSize: "5rem",
    },
  },
}));

const MyOrder = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const dispatch = useDispatch();
  const alert = useAlert();

  const { orders, loading, error } = useSelector((state) => state.myOrder);
  const { user } = useSelector((state) => state.userData);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    dispatch(myOrders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, alert, error]);

  const isOrders = !!orders?.length;

  return (
    <>
      {loading ? (
        <CricketBallLoader />
      ) : (
        <section className={classes.orderSection}>
          <MetaData title="My Orders" />
          <div className={classes.orderPageContainer}>
            <Typography variant="h6" className={classes.orderPageTitle}>
              Вашите поръчки
            </Typography>
            <Typography variant="body1" className={classes.orderPageText}>
              {orders?.length} поръчки през {currentYear}
            </Typography>
          </div>
          {!isOrders && (
            <div className={classes.noOrdersCaptionWrapper}>
              <ShoppingCartRounded />
              <h3>Все още нямате направени поръчки</h3>
            </div>
          )}
          {orders.map((item, i) => (
            <div
              className={classes.orderCard}
              key={`order-card-${item._id}-${i}-item`}>
              <OrderCard item={item} user={user} />
            </div>
          ))}
        </section>
      )}
    </>
  );
};

export default MyOrder;
