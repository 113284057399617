import React from "react";
import { QuantityController } from "./QuantityController";
import ItemCard from "./ItemCard";
import "./CartTableRow.css";

export default function CartTableRow({ item }) {
  return (
    <div className="cart-table-row">
      <div className="card-table-item-col">
        <ItemCard item={item} />
      </div>
      <div className="card-table-price-col">
        {!!item.oldPrice && (
          <span className="card-table-price-col-old-price">
            <span> {item.oldPrice.toFixed(2)}</span> <span>лв.</span>
          </span>
        )}
        <span>
          <span>{item.price.toFixed(2)}</span> <span>лв.</span>
        </span>
      </div>
      <div className="card-table-quantity-col">
        <QuantityController item={item} />
      </div>
      <div className="card-table-total-col">
        <span className="card-table-total-col-total">Total Inc. VAT</span>
        <span className="card-table-total-col-price">
          <span>{item.price.toFixed(2)}</span> <span>лв.</span>
        </span>
      </div>
    </div>
  );
}
