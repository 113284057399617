import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  submitButton: {
    marginTop: theme.spacing(2),
    width: "100%",
    backgroundColor: "black",
    color: "#FFFFFF",
    height: "4rem",
    "&:hover": {
      backgroundColor: "#9caccc",
      color: "#fff",
    },
  },
  outlinedInput: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "black",
      },
      "&:hover fieldset": {
        borderColor: "black",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#9caccc",
      },
    },
    "& .MuiInputBase-input": {
      color: "black",
      backgroundColor: "transparent !important",
    },
    "& .MuiInputLabel-root": {
      color: "black",
    },
    // Override browser autocomplete styles
    "& .MuiInputBase-input:-webkit-autofill, & .MuiInputBase-input:-webkit-autofill:hover, & .MuiInputBase-input:-webkit-autofill:focus, & .MuiInputBase-input:-webkit-autofill:active":
      {
        "-webkit-box-shadow": "0 0 0 30px transparent inset !important",
        "-webkit-text-fill-color": "black !important",
        transition: "background-color 5000s ease-in-out 0s",
      },
  },
  "error-outlinedInput": {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#f44336 !important",
      },
      "&:hover fieldset": {
        borderColor: "#f44336 !important",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#f44336 !important",
      },
    },
    "& .MuiFormLabel-root": {
      color: "#f44336 !important",
    },
  },
  "error-message": {
    fontSize: "12px",
    color: "red",
  },
  heading: {
    color: "black",
    marginBottom: theme.spacing(2),
    alignSelf: "flex-start",
  },
}));
