import axios from 'axios';

// Create a custom Axios instance
const axiosInstance = axios.create({
  baseURL:  `${process.env.REACT_APP_BACK_END_URL}`,
});

// Optionally set up interceptors
axiosInstance.interceptors.request.use(
  config => {
    // Do something before the request is sent, like adding an authorization token
    return config;
  },
  error => {
    // Handle the request error
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    // Do something with response data
    return response;
  },
  error => {
    // Handle the response error
    return Promise.reject(error);
  }
);

export default axiosInstance;
