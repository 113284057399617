import React from 'react';
import { Card, CardActionArea, CardMedia, CardContent, Typography, Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  card: {
    backgroundColor: '#8a2be2', // Violet background color
    borderRadius: '10px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    width: '250px',
    position: 'relative',
    textAlign: 'center',
    margin: '20px',
    color: '#ffffff', // White text color
  },
  media: {
    height: 140,
    backgroundSize: 'contain',
    backgroundColor: '#fff', // Background color behind the image
  },
  content: {
    padding: '10px',
  },
  productName: {
    fontWeight: 'bold',
    fontSize: '18px',
    fontFamily: 'Arial, sans-serif',
  },
  productPrice: {
    fontSize: '16px',
    color: '#ffffff', // White text color
  },
  limitedQuantity: {
    fontSize: '12px',
    fontStyle: 'italic',
    color: '#ffeb3b', // Yellow text color
    marginTop: '5px',
  },
  addToCart: {
    position: 'absolute',
    bottom: '10px',
    right: '10px',
  },
  addToCartBtn: {
    backgroundColor: '#ffba00',
    border: 'none',
    borderRadius: '50%',
    color: '#fff',
    fontSize: '16px',
    width: '30px',
    height: '30px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    '&:hover': {
      backgroundColor: '#e0a800',
    },
  },
}));

const CardSection = ({ product }) => {
  const classes = useStyles();
  const nameTruncated = product.name.length > 15 ? product.name.substring(0, 15) + '...' : product.name;
  const oldPrice = `$${product.price}`;

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia className={classes.media} image={product.images[0].url} />
        <CardContent className={classes.content}>
          <Typography gutterBottom color="textPrimary" className={classes.productName}>
            {nameTruncated}
          </Typography>
          <Typography variant="body2" color="textSecondary" className={classes.productPrice}>
            {oldPrice}
          </Typography>
          <Typography variant="body2" className={classes.limitedQuantity}>
            Купи сега, преди да свършат
          </Typography>
        </CardContent>
      </CardActionArea>
      <div className={classes.addToCart}>
        <button className={classes.addToCartBtn}>+</button>
      </div>
    </Card>
  );
};

export default CardSection;
