import React from "react";
import "./Shipping.css";

import MetaData from "../layouts/MataData/MataData";

import { makeStyles } from "@material-ui/core/styles";
import ShippingAddressForm from "../../collections/Form/ShippingAddressForm";
import CheckoutSteps from "./CheckoutSteps ";

const useStyles = makeStyles((theme) => ({
  shippingRoot: {
    width: "30%",
    margin: "auto",
    [theme.breakpoints.down(1280)]: {
      width: "60%",
      padding: "0 40px",
    },
    [theme.breakpoints.down(1024)]: {
      width: "100%",
    },
    [theme.breakpoints.down(768)]: {
      padding: "0 20px",
    },
  },
}));

const Shipping = () => {
  const classes = useStyles();

  return (
    <div className="shippingPage">
      <MetaData title={"Shipping Info"} />
      <CheckoutSteps activeStep={1} />
      <div className="shippingPage__container">
        <div className="shippingPage__container__left">
          <div className={classes.shippingRoot}>
            <ShippingAddressForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Shipping;
