import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  Stepper,
  Step,
  StepLabel,
  StepConnector,
  useMediaQuery,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { useTheme } from "@emotion/react";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: "1400px",
    margin: "0 auto 2rem",
    borderBottom: "1px solid #5f5f5f86",
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("xm")]: {
      fontSize: 12,
    },
  },
}));

const ColorlibConnector = withStyles((theme) => ({
  alternativeLabel: {
    top: 10,
  },
  active: {
    "& $line": {
      backgroundColor: "#9caccc",
    },
  },
  completed: {
    "& $line": {
      backgroundColor: "black",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#616161",
    borderRadius: 1,
  },
}))(StepConnector);

const useColorlibStepIconStyles = makeStyles((theme) => ({
  stepReader: {
    [theme.breakpoints.down("xs")]: {
      marginLeft: "-2rem",
    },
  },
  root: {
    backgroundColor: "#000000",
    zIndex: 1,
    color: "#FFFFFF",
    width: 40,
    height: 40,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: `2px solid ${theme.palette.background.paper}`,
    fontSize: 16,
    cursor: "pointer",
    "& .MuiStepLabel-labelContainer": {
      fontSize: 22,
    },

    margin: 0,
    [theme.breakpoints.down("sm")]: {
      width: 20,
      height: 20,
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
      fontSize: 14,

      "& .MuiStepLabel-label": {
        fontSize: 12,
      },
    },
  },
  stepperContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  step: {
    flex: "1 0 30%", // Adjust to fit three items per row (30% + spacing)
    boxSizing: "border-box", // Ensure padding and border are included in the width calculation
    marginBottom: "1rem", // Space between rows
    [theme.breakpoints.down("sm")]: {
      flex: "1 0 100%", // Full width on smaller screens
    },
  },
  active: {
    backgroundColor: "#9caccc",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    marginTop: "0rem",
  },
  completed: {
    backgroundColor: "#000000",
    margin: "0rem",
  },

  stepLabel: {
    cursor: "pointer",
    [theme.breakpoints.down("xs")]: {
      "&.MuiStepLabel-label ": {
        fontSize: 12,
      },
      fontSize: 12,
    },
  },
}));

const ColorlibStepIcon = ({ active, completed, icon, onClick }) => {
  const classes = useColorlibStepIconStyles();

  return (
    <div
      className={`${classes.root} ${active && classes.active} ${
        completed && classes.completed
      }`}
      onClick={onClick}
      style={
        !active && !completed
          ? { backgroundColor: "#666666", marginTop: "0", color: "white" }
          : null
      }>
      {icon}
    </div>
  );
};

const CheckoutSteps = ({ activeStep }) => {
  const classes = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const steps = [
    { label: "Количка", icon: "1", link: "/cart" },
    { label: "Доставка", icon: "2", link: "/shipping" },
    { label: "Плащане", icon: "3", link: "/process/payment" },
    { label: "Завършване на поръчката", icon: "4", link: "/success" },
  ];

  const handleStepClick = (stepIndex) => {
    if (stepIndex < activeStep) {
      history.push(steps[stepIndex].link);
    }
  };

  return (
    <div className={classes.stepReader} style={{ marginTop: "4rem" }}>
      <div className={classes.root}>
        <Stepper
          className={classes.stepperContainer}
          style={{
            flexDirection: isMobile ? "column" : "row",
            display: "flex",
            flexWrap: "wrap",
            backgroundColor: "transparent",
            alignItems: isMobile ? "start" : "center",
          }}
          activeStep={activeStep}
          connector={<ColorlibConnector />}>
          {steps.map((step, index) => (
            <Step
              key={`step-${index}-${step.label}`}
              className={classes.stepperContainer}>
              <StepLabel
                StepIconComponent={ColorlibStepIcon}
                onClick={() => handleStepClick(index)}
                className={classes.stepLabel}>
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>
    </div>
  );
};

export default CheckoutSteps;
