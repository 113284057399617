import axios from "../constants/axios";
import {
  LOGIN_REQUEST,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  CLEAR_ERRORS,
  REGISTER_USER_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PASSWORD_FAIL,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  UPDATE_USER_REQUEST,
  USER_DETAILS_FAIL,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_FAIL,
  DELETE_USER_SUCCESS,
} from "../constants/userConstanat";

// login user
export function login(email, password) {
  return async function (dispatch) {
    try {
      dispatch({ type: LOGIN_REQUEST });

      const config = { headers: { "Content-Type": "application/json" } };

      const res = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email, password }),
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );
      if (!res.ok) {
        // Handle errors
        const message = `An error has occurred: ${res.status}`;
        throw new Error(message);
      }

      const data = await res.json(); // Read and parse the response body as JSON

      dispatch({ type: LOGIN_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({ type: LOGIN_FAIL, payload: error.message });
    }
  };
}
// resgister user
export function signUp(signupData) {
  return async function (dispatch) {
    try {
      dispatch({ type: REGISTER_USER_REQUEST });
      const config = {
        headers: { "Content-Type": "multipart/form-data" },
      };

      const { data } = await axios.post(`/api/v1/register`, signupData, config);

      dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
    } catch (error) {
      dispatch({ type: REGISTER_USER_FAIL, payload: error.message });
    }
  };
}

// Load User (user Profile) if logged in before

export const load_UserProfile = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    // Check if user data is available in session storage
    const userData = sessionStorage.getItem("user");
    if (userData !== "undefined" && userData && userData !== undefined) {
      // Parse the user data from JSON format stored in session storage
      const user = JSON.parse(userData);
      dispatch({ type: LOAD_USER_SUCCESS, payload: user });
    } else {
      // If user data is not available in session storage, make a backend API call
      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/profile`,
        {
          method: "GET",
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      // Dispatch the action with the user data
      dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });

      // Save the user data to session storage for future use
      sessionStorage.setItem("user", JSON.stringify(data.user));
    }
  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.message });
  }
};

// logout user
export function logout() {
  return async function (dispatch) {
    try {
      sessionStorage.removeItem("user");
      const config = {
        withCredentials: true, // Include credentials (cookies, HTTP authentication)
      };

      const response = await axios.get("/api/v1/logout", config);

      dispatch({ type: LOGOUT_SUCCESS });

      // Dispatch the LOGOUT_SUCCESS action
      // dispatch({ type: LOGOUT_SUCCESS });
    } catch (error) {
      sessionStorage.removeItem("user");
      // dispatch({ type: LOGOUT_FAIL, payload: error.message });
    }
  };
}

// Update Profile =>

export function updateProfile(userData) {
  return async function (dispatch) {
    try {
      dispatch({ type: UPDATE_PROFILE_REQUEST });

      const { data } = await axios.put(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/profile/update`,
        userData
      );

      if (data.user !== undefined && data.user) {
        sessionStorage.removeItem("user");
        sessionStorage.setItem("user", JSON.stringify(data.user));
      }

      dispatch({
        type: UPDATE_PROFILE_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({ type: UPDATE_PROFILE_FAIL, payload: error.message });
    }
  };
}

export function updatePassword(userPassWord) {
  return async function (dispatch) {
    try {
      dispatch({ type: UPDATE_PASSWORD_REQUEST });

      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/password/update`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userPassWord),
          credentials: "include", // Ensure cookies are sent with the request
        }
      );

      if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json();

      dispatch({
        type: UPDATE_PASSWORD_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({ type: UPDATE_PASSWORD_FAIL, payload: error.message });
    }
  };
}
// forgetPassword;

export function forgetPassword(email) {
  return async function (dispatch) {
    try {
      dispatch({ type: FORGOT_PASSWORD_REQUEST });

      console.log(email);

      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/password/forgot`,
        {
          method: "POST",
          body: email,
          credentials: "include", // Ensure cookies are sent with the request if needed
        }
      );

      if (!response.ok) {
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json();

      dispatch({
        type: FORGOT_PASSWORD_SUCCESS,
        payload: data.message,
      });
    } catch (error) {
      dispatch({ type: FORGOT_PASSWORD_FAIL, payload: error.message });
    }
  };
}

// reset password action
export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const response = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/api/v1/password/reset/${token}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(passwords),
        credentials: "include", // Include credentials (cookies, HTTP authentication)
      }
    );

    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: RESET_PASSWORD_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.message,
    });
  }
};

// get All user Action --> admin
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true, // Include credentials (cookies, HTTP authentication)
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/users`,
      config
    );

    dispatch({
      type: ALL_USERS_SUCCESS,
      payload: data.users,
    });
  } catch (error) {
    dispatch({ type: ALL_USERS_FAIL, payload: error.message });
  }
};

// get User details --> admin

export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
      withCredentials: true, // Include credentials (cookies, HTTP authentication)
    };

    const { data } = await axios.get(`/api/v1/admin/user/${id}`, config);

    dispatch({
      type: USER_DETAILS_SUCCESS,
      payload: data.user,
    });
  } catch (error) {
    dispatch({ type: USER_DETAILS_FAIL, error: error.message });
  }
};

// upadte user role ---> admin
export const updateUser = (id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const response = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/user/${id}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
        credentials: "include", // Include credentials (cookies, HTTP authentication)
      }
    );

    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: UPDATE_USER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({ type: UPDATE_USER_FAIL, payload: error.message });
  }
};

// detele User ---> admin

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const response = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/user/${id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Include credentials (cookies, HTTP authentication)
      }
    );

    if (!response.ok) {
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    dispatch({
      type: DELETE_USER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({ type: DELETE_USER_FAIL, payload: error.message });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
