import { Avatar } from "@material-ui/core";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import DashboardIcon from "@material-ui/icons/Dashboard";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import AssignmentIcon from "@material-ui/icons/Assignment";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import { AccountCircle as AccountCircleIcon } from "@material-ui/icons";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import { logout } from "../../../actions/userAction";
import { useStyles } from "./UserDrawerContentStyle";
import HomeIcon from "@material-ui/icons/Home";
import InfoIcon from "@material-ui/icons/Info";
import Store from "@material-ui/icons/Store";

const UserDrawerContent = ({ setOpen }) => {
  const { isAuthenticated, user } = useSelector((state) => state.userData);
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();

  // console.log(user);

  const configNavItems = [
    {
      label: "Начало",
      href: "/",
      icon: <HomeIcon className={classes["menu-icon"]} />,
    },
    {
      label: "Профил",
      href: "/account",
      icon: <AccountCircleIcon className={classes["menu-icon"]} />,
    },
    {
      label: "Количка",
      href: "/cart",
      icon: <ShoppingCartIcon className={classes["menu-icon"]} />,
    },
    {
      label: "Поръчки",
      href: "/orders",
      icon: <AssignmentIcon className={classes["menu-icon"]} />,
    },
    {
      label: "Магазин",
      href: "/shop",
      icon: <Store className={classes["menu-icon"]} />,
    },
    {
      label: "За нас",
      href: "/about_us",
      icon: <InfoIcon className={classes["menu-icon"]} />,
    },
  ];

  return (
    <div className="modal-content">
      {!isAuthenticated ? (
        <div className={classes["welcome-message"]}>
          <strong>Добре дошли!</strong>
          <p>За да достъпите профила си, трябва да влезете в него.</p>
        </div>
      ) : (
        <>
          <div className={classes["profile-info"]}>
            <Avatar
              src={user.avatar?.url}
              alt="User Avatar"
              className="avatar"
              style={{ width: "68px", height: "68px" }}
            />
            <p className={classes["user-name"]}>
              <strong>Name :</strong> {user.name}
            </p>
            <p className={classes["user-email"]}>
              <strong>Email :</strong> {user.email}
            </p>
          </div>
        </>
      )}
      <div className="divider" />
      <div className={classes["profile-menu"]}>
        {user && user.role === "admin" && (
          <div
            className={`${classes["menu-item"]} ${
              location.pathname === "/admin/dashboard" && classes.active
            }`}
            onClick={() => {
              setOpen(false);
              history.push("/admin/dashboard");
            }}>
            <DashboardIcon className={classes["menu-icon"]} />
            <span>Табло</span>
          </div>
        )}
        {configNavItems.map((navItem, i) => (
          <div
            key={`drawer-nav-item-${i}`}
            className={`${classes["menu-item"]} ${
              location.pathname === navItem.href && classes.active
            }`}
            onClick={() => {
              setOpen(false);
              history.push(navItem.href);
            }}>
            {navItem.icon}
            <span>{navItem.label}</span>
          </div>
        ))}
        {!isAuthenticated ? (
          <div
            className={`${classes["menu-item"]} ${
              location.pathname === "/login" && classes.active
            }`}
            onClick={() => {
              setOpen(false);
              history.push("/login");
            }}>
            <LockOpenIcon className={classes["menu-icon"]} />
            <span>Вход</span>
          </div>
        ) : (
          <div
            className={classes["menu-item"]}
            onClick={() => {
              setOpen(false);
              dispatch(logout());
              // alert.success("УСПЕШЕН ИЗХОД ОТ ПРОФИЛА");
            }}>
            <ExitToAppIcon className={classes["menu-icon"]} />
            <span>Изход</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDrawerContent;
