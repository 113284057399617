import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { shippingAddressFormSchema } from "../../schema/ShippindAddressFormSchema";
import { Button, Grid, Typography } from "@material-ui/core";
import { useStyles } from "../../component/Input/styles";
import TextInput from "../../component/Input/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { saveShippingInfo } from "../../actions/cartAction";
import CheckboxInput from "../../component/Input/CheckboxInput";

const ShippingAddressForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { shippingInfo, cartItems } = useSelector((state) => state.cart);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(shippingAddressFormSchema),
    defaultValues: {
      firstName: shippingInfo?.firstName || "",
      lastName: shippingInfo?.lastName || "",
      address: shippingInfo?.address || "",
      city: shippingInfo?.city || "",
      pinCode: String(shippingInfo?.pinCode || ""),
      state: shippingInfo?.state || "",
      country: shippingInfo?.country || "",
      phoneNo: String(shippingInfo?.phoneNo || ""),
      email: shippingInfo?.email || "",
      deliveryMethod: [],
    },
  });

  const onSubmit = (formData) => {
    window.fbq("track", "InitiateCheckout", {
      content_ids: cartItems.map((item) => item._id),
      content_type: "product",
      value: cartItems.reduce((acc, currItem) => {
        return acc + currItem.quantity * currItem.price;
      }, 0),
      currency: "EUR",
      num_items: cartItems.length,
    });

    dispatch(
      saveShippingInfo({
        ...formData,
        deliveryMethod: formData.deliveryMethod[0],
        shippingPrice: shippingInfo.shippingPrice,
      })
    );
    history.push("/process/payment");
  };

  // const deliveryMethod = watch("deliveryMethod");

  const handleCheckboxChange = (event) => {
    const shippingInfo = JSON.parse(localStorage.getItem("shippingInfo"));
    const deliveryMethod = event.target.value;
    const shippingPrice = deliveryMethod === "До адрес" ? 15 : 10;

    setValue("deliveryMethod", [deliveryMethod]);
    dispatch(
      saveShippingInfo({ ...shippingInfo, deliveryMethod, shippingPrice })
    );
  };

  // console.log(watch("deliveryMethod"));

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h6" className={classes.heading}>
        Доставка
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextInput
            register={register}
            name="firstName"
            label="Име"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            register={register}
            name="lastName"
            label="Фамилия"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <p>Метод за доставка</p>
          <div>
            <CheckboxInput
              register={register}
              name="deliveryMethod"
              label="Доставка до адрес"
              value="До адрес"
              checked={shippingInfo.deliveryMethod === "До адрес"}
              onChange={handleCheckboxChange}
              errors={errors}
            />
            <CheckboxInput
              register={register}
              name="deliveryMethod"
              label="Доставка до офис на Еконт"
              value="До офис на Еконт"
              checked={shippingInfo.deliveryMethod === "До офис на Еконт"}
              onChange={handleCheckboxChange}
              errors={errors}
            />
            {errors.deliveryMethod && (
              <p className={classes["error-message"]}>
                {errors.deliveryMethod.message}
              </p>
            )}
          </div>
        </Grid>
        <Grid item xs={12}>
          <TextInput
            register={register}
            name="address"
            label="Адрес"
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            register={register}
            name="city"
            label="Град"
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            register={register}
            name="pinCode"
            label="Пощенски код"
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            register={register}
            name="state"
            label="Област"
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            register={register}
            name="country"
            label="Държава"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            register={register}
            name="phoneNo"
            label="Телефонен номер"
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <TextInput
            register={register}
            name="email"
            label="Имейл"
            errors={errors}
          />
        </Grid>

        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={classes.submitButton}>
            ПРОДЪЛЖИ КЪМ ПЛАЩАНЕ
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default ShippingAddressForm;
