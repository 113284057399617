import React from "react";
import "./TermsAndCondtion.css";
import MetaData from "../component/layouts/MataData/MataData";
import TermsImage from "../Image/about/tc.png";
const TermsAndConditionsPage = () => {
  return (
    <section className="terms-section">
      <div className="terms-container">
        <MetaData title="Terms and Conditions" />
        {/* <img
        src={TermsImage}
        alt="Terms and Conditions"
        className="terms-image"
      /> */}
        <div className="terms-overlay">
          <h1 className="terms-title">ПРАВИЛА ЗА ПОВЕРИТЕЛНОСТ</h1>
        </div>
        <div className="terms-content">
          <p>
            Благодарим ви, че пазарувате в Sneaker Brigade! Ценим вашия бизнес и
            интереса ви към нашето крикет оборудване. Искаме да се уверим, че
            имате добър опит при пазаруването от нашия уебсайт.
          </p>
          <p>
            С поръчването и закупуването на продукт от нашия уебсайт вие се
            съгласявате с следните условия, както и с нашите политики за връщане
            и гаранция, политика за поверителност и условия за ползване. Моля,
            прегледайте всичко внимателно, за да бъдете информирани за вашите
            права и задължения.
          </p>
          <h2>Приемане на тези условия</h2>
          <p>
            Вие („Клиент“) може да направите поръчки за продукти със Sneaker
            Brigade („ние“, „наш“) чрез нашия уебсайт или, в определени случаи,
            по телефона. С поръчването вие се съгласявате с тези Условия за
            продажба („Условия“) и потвърждавате, че ще ви предоставим
            продуктите съгласно тези Условия. Всяко условие или изискване в
            която и да е поръчка или друга форма на кореспонденция, което е в
            противоречие с тези Условия, ще бъде неприложимо и няма да има
            никаква сила и ефект, освен ако не е изрично съгласувано писмено от
            Sneaker Brigade.
          </p>
          <h2>Поръчки</h2>
          <p>
            Всички поръчки са предмет на приемане от Sneaker Brigade. Можем да
            откажем да приемем, анулираме или ограничим всяка поръчка или
            количество за поръчка по каквато и да е причина, дори след изпращане
            на потвърждение за поръчка. Ако анулираме поръчка след като сте били
            таксувани, ще ви възстановим сумата, която сте платили.
          </p>
          <h2>Предлагане на продукти</h2>
          <p>
            Всички описания на продукти на нашия уебсайт подлежат на промяна без
            предизвестие по наше собствено усмотрение. Запазваме си правото да
            променяме или прекратяваме продукт по всяко време. Въпреки че
            полагаме всички усилия да показваме цветовете и изображенията на
            продуктите точно, не можем да гарантираме, че дисплеят на вашето
            устройство ще бъде точно отражение на физическия елемент.
          </p>
          <h2>Цени</h2>
          <p>
            Всички цени подлежат на промяна, докато вашата поръчка не бъде
            приета от Sneaker Brigade. Цените, показани на уебсайта, не включват
            разходи за доставка, които се изчисляват и показват въз основа на
            избраната опция за доставка по време на касата. Цените на уебсайта
            могат да се различават от тези в физическите магазини, които
            предлагат продукти на Sneaker Brigade. Запазваме си правото да
            коригираме грешки в цените и да ви уведомим за всякакви промени,
            преди да продължим с вашата поръчка.
          </p>
          <h2>Специални оферти</h2>
          <p>
            От време на време можем да предлагаме специални промоции,
            включително отстъпки, ограничени издания на продукти или безплатна
            доставка. Тези оферти са предмет на промяна или прекратяване по
            всяко време.
          </p>
          <h2>Данъци</h2>
          <p>
            Цените, цитирани за продуктите, включват настоящия данък върху
            стоките и услугите (GST) на Сингапур. Клиентът е отговорен за
            плащането на всякакви данъци, освен тези, базирани на доходите на
            Sneaker Brigade. Ако Sneaker Brigade е задължен да събира и плаща
            данъци от името на клиента, можем да фактурираме клиента за тези
            суми.
          </p>
          <h2>Плащане</h2>
          <p>
            Всички поръчки трябва да бъдат платени изцяло преди изпращането.
            Приемаме плащане чрез MasterCard и Visa. Информацията за плащане се
            предоставя при поръчка и е предмет на проверка и наличност на
            средства.
          </p>
          <h2>Доставка</h2>
          <p>
            Наличните опции за доставка ще бъдат показани по време на процеса на
            касата. Всички времеви рамки за доставка, предоставени, са оценки и
            не можем да гарантираме точни дати на доставка. Не носим отговорност
            за закъснели доставки, но ако вече не се нуждаете от даден артикул
            поради закъснение, моля свържете се с нашия отдел за обслужване на
            клиенти. Вижте нашата политика за връщане за наличните опции. Всички
            рискове от загуба или повреда на продуктите преминават към вас при
            физическо получаване, а собствеността преминава към вас, когато
            продуктите се вземат от транспортния превозвач.
          </p>
          <h2>Връщания</h2>
          <p>
            След като поръчката е направена и приета, не можете да я анулирате
            без писменото съгласие на Sneaker Brigade. Можете да върнете
            продукти за възстановяване на покупната цена (с изключение на
            първоначалните разходи за доставка) плюс приложимите данъци.
            Разходите за връщане на продукти са отговорност на клиента.
            Продуктите трябва да бъдат върнати в рамките на тридесет дни от
            покупката.
          </p>
          <h2>Гаранция</h2>
          <p>
            За информация относно гаранцията, моля, вижте писмената гаранция,
            включена с продукта или страницата с гаранцията на нашия уебсайт.
          </p>
          <h2>Не за препродажба</h2>
          <p>
            Продуктите, продавани на нашия уебсайт, са само за крайни
            потребители и не за препродажба. Запазваме си правото да откажем или
            анулираме всяка поръчка, ако подозираме, че продуктите се купуват за
            препродажба.
          </p>
          <h2>Приложимо право / юрисдикция</h2>
          <p>
            Тези Условия се регулират и тълкуват в съответствие със законите на
            Сингапур.
          </p>
          <h2>Решаване на спорове и приложимо право</h2>
          <p>
            Всички спорове, произтичащи от или свързани с тези Условия, ще бъдат
            решавани чрез арбитраж в Сингапур, администриран от Сингапурския
            международен арбитражен център (SIAC), в съответствие с Арбитражните
            правила на SIAC. Езикът на арбитража ще бъде английски.
          </p>
          <h2>Обезщетение</h2>
          <p>
            Вие се съгласявате да обезщетите и задържите Sneaker Brigade
            безвреден от всякакви искове, разходи, производства, загуби или
            изисквания, произтичащи от вашето нарушение на тези Условия или от
            който и да е използващ вашия акаунт.
          </p>
          <h2>Цялото споразумение</h2>
          <p>
            Тези Условия представляват цялото споразумение между Sneaker Brigade
            и Клиента, като заменят всички предходни или съвременни
            споразумения, представителства, гаранции и разбирания.
          </p>
          <h2>Разделност</h2>
          <p>
            Ако която и да е разпоредба на тези Условия е призната за невалидна
            или неприложима, останалите разпоредби ще останат в пълна сила и
            ефект, а невалидната или неприложимата разпоредба ще бъде тълкувана,
            за да даде максимален ефект на намерението на страните.
          </p>
          <h2>Изключителност</h2>
          <p>
            Правата, задълженията, отговорностите и средствата за защита във
            връзка с продуктите са изключително тези, изразени в тези Условия.
            Отказите, освобождаванията, ограниченията на отговорността и
            средствата за защита, изразени в тези Условия, се прилагат дори в
            случай на неизпълнение, небрежност, нарушение на договора, строга
            отговорност или друга причина за действие.
          </p>
        </div>
      </div>
    </section>
  );
};

export default TermsAndConditionsPage;
