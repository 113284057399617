import { IconButton, Input } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch } from "react-redux";
import "./QuantityController.css";

import {
  addItemToCart,
  removeItemFromCart,
} from "../../../../actions/cartAction";
import { FaTrash } from "react-icons/fa";

export const QuantityController = ({ item }) => {
  const dispatch = useDispatch();

  const increaseQuantity = (id, size) => {
    dispatch(addItemToCart(id, size, true));
  };

  const decreaseQuantity = (id, size) => {
    dispatch(addItemToCart(id, size, false));
  };

  const deleteCartItems = (id, size, name) => {
    dispatch(removeItemFromCart(id, size));
  };

  const showDeleteBtn = item.quantity === 1;
  const disableAddButton = item.stock[item.size] <= 1;

  return (
    <div className="quantity-control-wrapper">
      {showDeleteBtn ? (
        <IconButton
          onClick={() => deleteCartItems(item.productId, item.size, item.name)}
          className="add-item-decrease">
          <FaTrash className="add-item-decrease-icon" />
        </IconButton>
      ) : (
        <IconButton
          onClick={() => decreaseQuantity(item.productId, item.size)}
          className="add-item-decrease">
          <RemoveIcon />
        </IconButton>
      )}
      <Input
        readOnly
        type="number"
        value={item.quantity}
        className="item-quantity"
      />
      <IconButton
        disabled={disableAddButton}
        onClick={() => increaseQuantity(item.productId, item.size)}
        className="add-item-increase">
        <AddIcon />
      </IconButton>
    </div>
  );
};
