import { Button, Drawer } from "@mui/material";
import React from "react";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { useStyles } from "./UserDrawerStyles";
import UserDrawerContent from "./UserDrawerContent";
import PersonIcon from "@mui/icons-material/Person";
const UserDrawer = () => {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();

  return (
    <>
      <Button
        onClick={() => setOpen(!open)}
        className={classes["menu-trigger-button"]}>
        <PersonIcon className={classes["menu-icon"]} />
        {open ? (
          <ArrowDropUpIcon className={classes["arrow-icon"]} />
        ) : (
          <ArrowDropDownIcon className={classes["arrow-icon"]} />
        )}
      </Button>
      <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
        <div className={classes["drawer-content"]}>
          <UserDrawerContent setOpen={setOpen} />
        </div>
      </Drawer>
    </>
  );
};

export default UserDrawer;
