import axios from "../constants/axios";

import {
  ALL_PRODUCT_REQUEST,
  ALL_PRODUCT_SUCCESS,
  ALL_PRODUCT_FAIL,
  PRODUCT_DETAILS_REQUEST,
  PRODUCT_DETAILS_FAIL,
  PRODUCT_DETAILS_SUCCESS,
  NEW_REVIEW_SUCCESS,
  NEW_REVIEW_REQUEST,
  NEW_REVIEW_FAIL,
  ADMIN_PRODUCT_FAIL,
  ADMIN_PRODUCT_REQUEST,
  ADMIN_PRODUCT_SUCCESS,
  NEW_PRODUCT_REQUEST,
  NEW_PRODUCT_SUCCESS,
  NEW_PRODUCT_FAIL,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAIL,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAIL,
  ALL_REVIEW_REQUEST,
  ALL_REVIEW_SUCCESS,
  DELETE_REVIEW_REQUEST,
  DELETE_REVIEW_SUCCESS,
  DELETE_REVIEW_FAIL,
  CLEAR_ERRORS,
  ALL_REVIEW_FAIL,
  SHUFFLED_PRODUCTS_SUCCESS,
  SHUFFLED_PRODUCTS_FAIL,
  SHUFFLED_PRODUCTS_REQUEST,
  LATEST_PRODUCTS_SUCCESS,
  LATEST_PRODUCTS_FAIL,
  LATEST_PRODUCTS_REQUEST,
  DELETE_DUMMY_PRODUCT_REQUEST,
  DELETE_DUMMY_PRODUCT_SUCCESS,
  DELETE_DUMMY_PRODUCT_FAIL,
  NEW_DUMMY_PRODUCT_REQUEST,
  NEW_DUMMY_PRODUCT_SUCCESS,
  NEW_DUMMY_PRODUCT_FAIL,
  ADMIN_DUMMY_PRODUCT_REQUEST,
  ADMIN_DUMMY_PRODUCT_SUCCESS,
  ADMIN_DUMMY_PRODUCT_FAIL,
  UPDATE_DUMMY_PRODUCT_SUCCESS,
  UPDATE_DUMMY_PRODUCT_FAIL,
  UPDATE_DUMMY_PRODUCT_REQUEST,
  SET_CLOAKED,
  COUNTRIES_REQUEST,
  COUNTRIES_SUCCESS,
  COUNTRIES_FAIL,
  UPDATE_COUNTRIES_REQUEST,
  UPDATE_COUNTRIES_SUCCESS,
  UPDATE_COUNTRIES_FAIL,
} from "../constants/productsConstatns";
import { getCloakedFromCookie } from "../reducers/productReducers";

const urlParams = new URLSearchParams(window.location.search);
let sourceParam = urlParams.get("source");

// get ALL Products
export const getProduct = (
  keyword = "",
  currentPage = 1,
  price = [0, 100000],
  category,
  ratings = 0
) => {
  return async (dispatch) => {
    try {
      // initial state :
      dispatch({
        type: ALL_PRODUCT_REQUEST,
      });

      let link = `/api/v1/product?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${ratings}`;

      // when category selected by user then using another link
      if (category) {
        link = `/api/v1/product?keyword=${keyword}&page=${currentPage}&price[gte]=${price[0]}&price[lte]=${price[1]}&ratings[gte]=${ratings}&category=${category}`;
      }

      const { data } = await axios
        .get(link, {
          headers: {
            Source: sourceParam || null,
          },
          withCredentials: true, // Include credentials (cookies, HTTP authentication)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      data.products = data.products.filter((item) => item.Stock !== null);

      dispatch({
        type: SET_CLOAKED,
        payload: false,
      });
      dispatch({
        type: ALL_PRODUCT_SUCCESS,
        payload: data,
      });
      window.scrollTo(0, 0);
    } catch (error) {
      dispatch({
        type: ALL_PRODUCT_FAIL,
        payload: error.message,
      });
    }
  };
};

// Get Latest Products
export const getLatestProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: LATEST_PRODUCTS_REQUEST,
      });

      const { data } = await axios
        .get("/api/v1/products/latest", {
          headers: {
            Source: sourceParam || null,
          },
          withCredentials: true, // Include credentials (cookies, HTTP authentication)
        })
        .catch((error) => {
          console.error("Error fetching latest products:", error);
        });

      dispatch({
        type: SET_CLOAKED,
        payload: false,
      });

      dispatch({
        type: LATEST_PRODUCTS_SUCCESS,
        payload: data.products,
      });
    } catch (error) {
      dispatch({
        type: LATEST_PRODUCTS_FAIL,
        payload: error.message,
      });
    }
  };
};

// Get Shuffled Products
export const getShuffledProducts = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: SHUFFLED_PRODUCTS_REQUEST,
      });
      const { data } = await axios
        .get("/api/v1/products/shuffled", {
          headers: {
            Source: sourceParam || null,
          },
          withCredentials: true, // Include credentials (cookies, HTTP authentication)
        })
        .catch((error) => {
          console.error("Error fetching shuffled products:", error);
        });

      dispatch({
        type: SET_CLOAKED,
        payload: false,
      });

      dispatch({
        type: SHUFFLED_PRODUCTS_SUCCESS,
        payload: data.products,
      });
    } catch (error) {
      dispatch({
        type: SHUFFLED_PRODUCTS_FAIL,
        payload: error.message,
      });
    }
  };
};

export const getIsCloaked = (sourceParam) => {
  return async (dispatch) => {
    try {
      const response = await axios.get("/api/v1/cloaked", {
        headers: {
          Source: sourceParam || null, // Default to empty string if sourceParam is null
        },
        withCredentials: true, // Include credentials (cookies, HTTP authentication)
      });

      // Log the response or perform any dispatch actions as needed
      if (response && response.data) {
        // Dispatch an action if necessary, e.g.:
        // dispatch({ type: 'FETCH_CLOAKED_SUCCESS', payload: response.data });
      } else {
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching cloaked status:", error);
      // Optionally, dispatch an error action, e.g.:
      // dispatch({ type: 'FETCH_CLOAKED_FAILURE', error });
    }
  };
};

// Get Products Details
export const getProductDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: PRODUCT_DETAILS_REQUEST,
      });

      const { data } = await axios
        .get(`/api/v1/product/${id}`, {
          headers: {
            Source: sourceParam || null,
          },
          withCredentials: true, // Include credentials (cookies, HTTP authentication)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      dispatch({
        type: PRODUCT_DETAILS_SUCCESS,
        payload: data.Product,
      });
    } catch (error) {
      dispatch({
        type: PRODUCT_DETAILS_FAIL,
        payload: error.message,
      });
    }
  };
};

// Get Products Details
export const getCountries = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: COUNTRIES_REQUEST,
      });
      const { data } = await axios
        .get(`/api/v1/admin/countries`, {
          headers: {
            Source: sourceParam || null,
          },
          withCredentials: true, // Include credentials (cookies, HTTP authentication)
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });

      dispatch({
        type: COUNTRIES_SUCCESS,
        payload: data.countries[0].countries,
      });
    } catch (error) {
      dispatch({
        type: COUNTRIES_FAIL,
        payload: error.message,
      });
    }
  };
};

//Add new Review
export const newReview = (reviewData) => async (dispatch) => {
  try {
    dispatch({ type: NEW_REVIEW_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(`/api/v1/review/new`, reviewData, config);

    dispatch({ type: NEW_REVIEW_SUCCESS, payload: data?.success });
  } catch (error) {
    dispatch({ type: NEW_REVIEW_FAIL, payload: error.message });
  }
};

// admin product request :
export const getAdminProducts = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_PRODUCT_REQUEST });

    const response = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/products`,
      {
        method: "GET",
        credentials: "include", // Include credentials (cookies, HTTP authentication)
      }
    );

    if (!response?.ok) {
      // Handle errors
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json(); // Read and parse the response body as JSON

    // Dispatch the action with the products data
    dispatch({ type: ADMIN_PRODUCT_SUCCESS, payload: data.products });
  } catch (error) {
    dispatch({ type: ADMIN_PRODUCT_FAIL, payload: error.message });
  }
};
export const getAdminDummyProducts = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_DUMMY_PRODUCT_REQUEST });

    const response = await fetch(
      `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/dummy_products`,
      {
        method: "GET",
        credentials: "include", // Include credentials (cookies, HTTP authentication)
      }
    );

    if (!response?.ok) {
      // Handle errors
      const message = `An error has occurred: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json(); // Read and parse the response body as JSON

    // Dispatch the action with the products data
    dispatch({ type: ADMIN_DUMMY_PRODUCT_SUCCESS, payload: data.products });
  } catch (error) {
    dispatch({ type: ADMIN_DUMMY_PRODUCT_FAIL, payload: error.message });
  }
};

// Create Product
export function createProduct(productData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: NEW_PRODUCT_REQUEST,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/product/new`,
        {
          method: "POST",
          body: productData,
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      dispatch({
        type: NEW_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEW_PRODUCT_FAIL,
        payload: error.message,
      });
    }
  };
}
// Create Product
export function createDummyProduct(productData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: NEW_DUMMY_PRODUCT_REQUEST,
      });

      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/dummy_product/new`,
        {
          method: "POST",
          body: productData,
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      dispatch({
        type: NEW_DUMMY_PRODUCT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: NEW_DUMMY_PRODUCT_FAIL,
        payload: error.message,
      });
    }
  };
}

// Delete Product request

export function deleteProduct(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: DELETE_PRODUCT_REQUEST });

      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/product/${id}`,
        {
          method: "DELETE",
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({ type: DELETE_PRODUCT_FAIL, payload: error.message });
    }
  };
}
export function deleteDummyProduct(id) {
  return async function (dispatch) {
    try {
      dispatch({ type: DELETE_DUMMY_PRODUCT_REQUEST });

      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/dummy_product/${id}`,
        {
          method: "DELETE",
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      dispatch({ type: DELETE_DUMMY_PRODUCT_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({ type: DELETE_DUMMY_PRODUCT_FAIL, payload: error.message });
    }
  };
}

// updateProduct;
export function updateProduct(id, productData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: UPDATE_PRODUCT_REQUEST,
      });

      console.log("PRODUCT DATA >>>>>>", productData);

      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/product/${id}`,
        {
          method: "PUT",
          body: productData,
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      dispatch({
        type: UPDATE_PRODUCT_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_PRODUCT_FAIL,
        payload: error.message,
      });
    }
  };
}
// updateProduct;
export function updateCountries(countryData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: UPDATE_COUNTRIES_REQUEST,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/countries`,
        {
          method: "PUT",
          body: countryData,
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      dispatch({
        type: UPDATE_COUNTRIES_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_COUNTRIES_FAIL,
        payload: error.message,
      });
    }
  };
}
export function updateDummyProduct(id, productData) {
  return async function (dispatch) {
    try {
      dispatch({
        type: UPDATE_DUMMY_PRODUCT_REQUEST,
      });
      const response = await fetch(
        `${process.env.REACT_APP_BACK_END_URL}/api/v1/admin/dummy_product/${id}`,
        {
          method: "PUT",
          body: productData,
          credentials: "include", // Include credentials (cookies, HTTP authentication)
        }
      );

      if (!response?.ok) {
        // Handle errors
        const message = `An error has occurred: ${response.status}`;
        throw new Error(message);
      }

      const data = await response.json(); // Read and parse the response body as JSON

      dispatch({
        type: UPDATE_DUMMY_PRODUCT_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_DUMMY_PRODUCT_FAIL,
        payload: error.message,
      });
    }
  };
}
// get all review of product admin ==>
export const getAllreviews = (productId) => async (dispatch) => {
  try {
    dispatch({ type: ALL_REVIEW_REQUEST });

    const { data } = await axios.get(`/api/v1/reviews?id=${productId}`);
    dispatch({ type: ALL_REVIEW_SUCCESS, payload: data.reviews });
  } catch (error) {
    dispatch({ type: ALL_REVIEW_FAIL, payload: error.message });
  }
};

// delete product review
export const deleteProductReview =
  (reviewId, productId) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_REVIEW_REQUEST });

      const { data } = await axios.delete(
        `/api/v1/product/reviews/delete?id=${reviewId}&productId=${productId}`
      );

      dispatch({ type: DELETE_REVIEW_SUCCESS, payload: data.success });
    } catch (error) {
      dispatch({ type: DELETE_REVIEW_FAIL, payload: error.message });
    }
  };

// clear error
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
