import z from "zod";

export const shippingAddressFormSchema = z.object({
  firstName: z
    .string()
    .trim()
    .min(1, "Моля въведете вашето име")
    .refine((value) => value.trim().length > 0, "Моля въведете вашето име"),
  lastName: z
    .string()
    .trim()
    .min(1, "Моля въведете вашата фамилия")
    .refine((value) => value.trim().length > 0, "Моля въведете вашата фамилия"),
  email: z
    .string()
    .trim()
    .email("Невалиден имейл адрес")
    .min(1, "Моля въведете вашият имейл адрес"),
  phoneNo: z
    .string()
    .trim()
    .min(8, "Моля въведете валиден телефонен номер")
    .max(22, "Моля въведете валиден телефонен номер")
    .refine(
      (value) => value.trim().length > 0 && /^[\d\s]+$/.test(value),
      "Моля въведете валиден телефонен номер. Използвайте само цифри и интервали!"
    ),
  pinCode: z
    .string()
    .trim()
    .min(4, "Моля въведете валиден пощенски код")
    .max(5, "Моля въведете валиден пощенски код")
    .refine(
      (value) => value.trim().length > 0 && /^\d+$/.test(value),
      "Моля въведете валиден пощенски код"
    ),
  country: z
    .string()
    .trim()
    .min(1, "Моля въведете вашата държава")
    .refine((value) => value.trim().length > 0, "Моля въведете вашата държава"),
  state: z
    .string()
    .trim()
    .min(1, "Моля въведете вашата област")
    .refine((value) => value.trim().length > 0, "Моля въведете вашата област"),
  city: z
    .string()
    .trim()
    .min(1, "Моля въведете ваш град")
    .refine((value) => value.trim().length > 0, "Моля въведете ваш град"),
  address: z
    .string()
    .trim()
    .min(1, "Моля въведете адрес")
    .refine((value) => value.trim().length > 0, "Моля въведете адрес"),
  deliveryMethod: z
    .array(z.string())
    .nonempty("Моля изберете метод на доставка"),
});
