import React from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import MetaData from "../component/layouts/MataData/MataData";
import TermsImage from "../Image/about/tc.png";
import { Link } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  about_us_wrapper: {
    width: "100%",
    maxWidth: "1440px",
    margin: "0 auto",
    padding: "0 40px",
    [theme.breakpoints.down(768)]: {
      padding: "0 20px",
    },
  },
  about_us: {
    paddingTop: "6rem",
    backgroundColor: "white !important",
    paddingBottom: "1rem",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },

  container_12: {
    padding: "0 2rem",
    textAlign: "center",
    backgroundColor: "white !important",
    maxWidth: "100%",
    marginBottom: "20px",
  },
  image_about: {
    width: "auto",
    maxHeight: "60vh",
  },
  title_about: {
    color: "#414141",
    fontFamily: "Roboto",
    fontWeight: "400 !important",
    [theme.breakpoints.down(768)]: {
      fontSize: "40px !important",
    },
  },
  heading12_about: {
    fontWeight: "400 !important",
    color: "#414141",
    textAlign: "center",
    [theme.breakpoints.down(768)]: {
      fontSize: "40px !important",
    },
  },
  introText_about: {
    maxWidth: "800px",
    lineHeight: "1.5",
    margin: "1.5rem 0",
    color: "#292929",
    fontSize: "1.2rem",
    fontWeight: "400 !important",
    textAlign: "justify",
    padding: "0.8rem 1rem",
    [theme.breakpoints.down(768)]: {
      fontSize: "14px !important",
      textAlign: "left",
      padding: "0.8rem 0",
    },
  },
  infoText_about: {
    lineHeight: "1.5",
    color: "#292929",
    fontSize: "1.1rem",
    fontWeight: "400 !important",
    textAlign: "center",
    padding: "0.8rem 1rem",
    [theme.breakpoints.down(768)]: {
      fontSize: "14px !important",
      textAlign: "left",
      padding: "0.8rem 0",
    },
  },
  buttonContainer_about: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "1rem 0",
    width: "100%",
    [theme.breakpoints.down(768)]: {
      "& a": {
        width: "100%",
      },
    },
  },
  button1_about: {
    backgroundColor: "#000000 !important",
    color: "white !important",
    width: "fit-content !important",
    padding: "0.8rem 2rem   !important",
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "#9caccc !important",
      color: "white !important",
    },
    [theme.breakpoints.down(768)]: {
      width: "100% !important",
    },
  },
  button2_about: {
    backgroundColor: "#292929 !important",
    color: "white   !important",
    width: "fit-content     !important",
    padding: "0.8rem 2rem   !important",
    marginLeft: "1.3rem !important",
    borderRadius: "5px !important",
    "&:hover": {
      backgroundColor: "#9caccc !important",
      color: "white !important",
    },
  },
}));

const About_UsPage = () => {
  const classes = useStyles();

  return (
    <section className={classes.about_us_wrapper}>
      <div className={classes.about_us}>
        <MetaData title={"About Us"} />
        <Container className={classes.container_12}>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} md={6}>
              <img
                src={TermsImage}
                alt="Sneaker Brigade "
                className={classes.image_about}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography
                variant="h2"
                component="h1"
                className={classes.title_about}>
                За нас
              </Typography>
              <Typography variant="body1" className={classes.introText_about}>
                SneakerBrigade е онлайн магазин за маратонки, който предлага
                богат асортимент от най-популярните марки. В нашия каталог ще
                откриете продукти на водещи брандове като Nike, Air Jordan, New
                Balance и Yeezy. Независимо дали сте запален колекционер или
                търсите перфектния чифт за ежедневието, SneakerBrigade е
                мястото, където ще намерите всичко необходимо. Нашата мисия е да
                ви предоставим най-добрите модели на пазара, съчетавайки
                качество, стил и комфорт. Заповядайте при нас и се насладете на
                изключително обслужване и бърза доставка.
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container className={classes.container_12}>
          <Typography
            variant="h3"
            component="h1"
            className={classes.heading12_about}>
            Кои сме ние ?
          </Typography>
          <Typography variant="body1" className={classes.infoText_about}>
            SneakerBrigade е основана от екип от ентусиасти на маратонките,
            които споделят обща страст към уникалния дизайн и високото качество
            на спортната обувка. Ние вярваме, че маратонките не са просто
            обувки, а израз на личния стил и начин на живот. Нашият екип
            внимателно подбира всеки модел, за да ви предложим най-новите и
            търсени маратонки на пазара. Стремим се да бъдем в крак с най-новите
            тенденции и да предложим нещо за всеки вкус – от класическите и
            вечни модели до най-модерните и екстравагантни дизайни. В
            SneakerBrigade се гордеем с нашата ангажираност към клиентите. Ние
            сме тук, за да ви помогнем с професионални съвети и персонализирани
            препоръки, за да намерите перфектния чифт маратонки. Нашата цел е да
            превърнем всяко ваше пазаруване в приятно и лесно изживяване.
          </Typography>
          <div className={classes.buttonContainer_about}>
            <Link to="/shop" style={{ textDecoration: "none", color: "none" }}>
              <Button variant="contained" className={classes.button1_about}>
                Магазин
              </Button>
            </Link>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default About_UsPage;
