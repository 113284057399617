import React from "react";
import { Link } from "react-router-dom";
import "./Privacy.css";
import MetaData from "../component/layouts/MataData/MataData";

const PrivacyPolicy = () => {
  return (
    <section className="privacy-policy-section">
      <div className="privacy-policy-container">
        <MetaData title={"Privacy Policy"} />
        <div className="container___">
          <h2>ЗАМЯНА</h2>
          <p>Доставката в двете посоки се поема от страна на клиента.</p>
          <h3>Процедура:</h3>

          <ul>
            <li>
              Трябва да ни уведомите предварително, че желаете да извършите
              замяна на email –{" "}
              <a href="mailto:support@sneakerbrigade.com">
                support@sneakerbrigade.com
              </a>
            </li>
          </ul>
          <ul>
            <li>
              Моля, не изпращайте пратка към нас преди да сме потвърдили, че
              можем да извършим замяна.
            </li>
            <li>
              <em>Sneaker Brigade</em> не се задължава да заменя продукти, които
              не могат да бъдат заменени поради липса на наличност от желаните
              продукти!
            </li>
          </ul>
          <ul>
            <li>
              Трябва да изпратите пратката до адреса, който е уговорен при
              свързването с нашия екип.
            </li>
          </ul>
          <ul>
            <li>
              Моля, опаковайте пратката с оригиналната й опаковка с цел
              предпазването й от замърсяване или повреждане по време на
              доставката до нас.
            </li>
          </ul>

          <p>Заменяме само продукти, които отговарят на следните изисквания:</p>
          <ul>
            <li>Продуктът не е носен или пран</li>
            <li>Продуктът не е повреден и няма дефекти</li>
            <li>
              Продуктът се връща в оригиналната опаковка, заедно с всички
              етикети, по начин, по който е бил получен.
            </li>
          </ul>
          <p>
            Моля, имайте предвид, че изпращането на новата пратка се случва в
            рамките на 3 дневен срок след обработката на върнатата пратка.
          </p>

          <h2>ВРЪЩАНЕ</h2>
          <p>Доставката се поема от страна на клиента.</p>
          <h3>Процедура:</h3>
          <ul>
            <li>
              Трябва да ни уведомите предварително, че желаете да върнете
              артикули с цел възстановяване на сумата на email –{" "}
              <a href="mailto:support@sneakerbrigade.com">
                support@sneakerbrigade.com
              </a>
            </li>
            <li>
              Трябва да изпратите пратката до адреса, който е уговорен при
              свързването с нашия екип.
            </li>
          </ul>
          <h3>ВАЖНА ИНФОРМАЦИЯ:</h3>
          <p>
            Потребителят има право да върне закупената от него стока в рамките
            на 14 работни дни от датата на получаването й. За целта продуктът
            трябва да е в оригиналната си опаковка, без следи от употреба или
            нарушаване на търговския вид на продукта в съответствие с
            разпоредбите на чл.55(ал.1) от 33П. След проверка в срок от 14 дни
            ще бъде възстановена сумата на купувача, който поема всички
            транспортни разходи.
          </p>

          <h3>Изисквания за двете процедури:</h3>
          <ul>
            <li>
              Замяната или рекламацията да се случат в 14-дневен срок от датата
              на получаване на пратката.
            </li>
            <li>
              Продукти, подлежащи на връщане или замяна са тези, които не са
              преминали никаква обработка (пране, гладене, рязане и други
              действия, нарушаващи целостта им.)
            </li>
            <li>
              Всички пратки се отварят преди изплащането на сумите обратно. Щом
              се уверим, че пратката отговаря на посочените условия, сумата ще
              бъде изплатена обратно към вас в срок от 3 работни дни с пощенски
              паричен превод. За получаването на паричен превод в офиса на
              куриера ще Ви изискат единствено лична карта. При неспазване на
              описаните условия, искането за рекламация няма да бъде разгледано.
            </li>
          </ul>

          <h1>Политика за поверителност на Sneaker Brigade</h1>
          <p>Дата на влизане в сила: 23-12-2021</p>
          <p>
            В Sneaker Brigade ценим поверителността на нашите клиенти и сме
            ангажирани с опазването на вашата лична информация. Тази политика за
            поверителност обяснява как събираме, използваме, разкриваме и
            защитаваме вашата информация когато използвате нашия уебсайт и
            услуги. Моля, прочетете внимателно тази политика за поверителност. С
            достъпа или използването на нашия уебсайт и услуги, вие
            потвърждавате, че сте прочели, разбрали и се съгласявате да бъдете
            обвързани от всички условия, изложени в тази политика за
            поверителност.
          </p>
          <h2>1. Информация, която събираме</h2>
          <h3>1.1 Лична информация:</h3>
          <p>
            Може да събираме лична информация, която доброволно предоставяте,
            когато регистрирате акаунт, направите поръчка, абонирате се за нашия
            бюлетин, участвате в конкурси или анкети, или се свържете с нас за
            поддръжка. Тази информация може да включва вашето име, имейл адрес,
            телефонен номер, адрес за доставка, адрес за фактуриране и данни за
            плащане.
          </p>
          <h3>1.2 Нелична информация:</h3>
          <p>
            Когато взаимодействате с нашия уебсайт, може да събираме нелична
            информация за вашето устройство, действията ви при браузване и
            моделите на използване. Тази информация може да включва вашия IP
            адрес, тип браузър, операционна система, препращащи URL адреси и
            взаимодействия с нашия уебсайт.
          </p>
          <h2>2. Използване на информацията</h2>
          <h3>2.1 Лична информация:</h3>
          <p>Може да използваме събраната лична информация за:</p>
          <ul>
            <li>Обработка и изпълнение на вашите поръчки</li>
            <li>Предоставяне на клиентска поддръжка и отговор на запитвания</li>
            <li>
              Изпращане на промоционални оферти, бюлетини и маркетингови
              съобщения (можете да се отпишете по всяко време)
            </li>
            <li>Подобряване на нашия уебсайт, продукти и услуги</li>
            <li>Персонализиране на вашето преживяване на нашия уебсайт</li>
            <li>
              Предотвратяване на измамни дейности и осигуряване на сигурността
              на нашата платформа
            </li>
          </ul>
          <h3>2.2 Нелична информация:</h3>
          <p>
            Може да използваме нелична информация за различни цели, включително:
          </p>
          <ul>
            <li>Анализиране на тенденции и поведение на потребителите</li>
            <li>
              Мониторинг и подобряване на функционалността на нашия уебсайт
            </li>
            <li>Персонализиране на съдържание и реклами</li>
            <li>Генериране на обобщени статистически данни</li>
          </ul>
          <h2>3. Разкриване на информация</h2>
          <p>
            Може да разкрием вашата информация на трети страни при следните
            обстоятелства:
          </p>
          <ul>
            <li>
              На нашите доверени доставчици на услуги, които ни помагат в
              управлението на нашия бизнес и предоставянето на услуги към вас
            </li>
            <li>
              За да спазим правни задължения, да наложим нашите политики или да
              отговорим на правни искания
            </li>
            <li>
              В случай на сливане, придобиване или продажба на всички или част
              от нашите бизнес активи
            </li>
            <li>С вашето съгласие или по ваше указание</li>
          </ul>
          <h2>4. Сигурност</h2>
          <p>
            Предприемаме разумни мерки за защита на вашата информация от
            неоторизиран достъп, разкриване, промяна или унищожение. Въпреки
            това, моля имайте предвид, че няма метод на предаване през интернет
            или електронно съхранение, който да е напълно сигурен, и не можем да
            гарантираме абсолютна сигурност.
          </p>
          <h2>5. Поверителност на децата</h2>
          <p>
            Нашият уебсайт и услуги не са предназначени за деца под 13-годишна
            възраст. Не събираме съзнателно лична информация от деца. Ако
            разберем, че сме събрали лична информация от дете без родителско
            съгласие, ще предприемем стъпки за премахване на тази информация от
            нашите сървъри.
          </p>
          <h2>6. Промени в тази политика за поверителност</h2>
          <p>
            Може да актуализираме нашата политика за поверителност от време на
            време. Всякакви промени ще бъдат публикувани на тази страница и
            новата политика за поверителност ще влезе в сила веднага след
            публикуването. Насърчаваме ви да преглеждате тази политика за
            поверителност периодично за актуализации или промени.
          </p>
          <h2>7. Свържете се с нас</h2>
          <p>
            Ако имате въпроси, притеснения или предложения относно тази политика
            за поверителност, моля, свържете се с нас на{" "}
            <a href="mailto:support@sneakerbrigade.com">
              support@sneakerbrigade.com
            </a>
          </p>
          <p>
            Използвайки уебсайта и услугите на Sneaker Brigade, вие се
            съгласявате с събирането, използването и разкриването на вашата
            информация, както е описано в тази политика за поверителност.
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
