import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "menu-trigger-button": {
    display: "block !important",
    width: "fit-content !important",
    minWidth: "fit-content !important",
    padding: "0 !important",
    margin: "0 !important",
  },
  "menu-icon": {
    display: "flex",
    alignItems: "center",
    position: "relative",
    fontSize: "2rem",
    color: "#000",
    height: "40px !important",
    width: "40px !important",
  },

  "arrow-icon": {
    color: "#000",
    fontSize: "22px",
    marginBottom: "5px",
  },

  "drawer-content": {
    backgroundColor: "#121212",
    height: "100%",
    width: "100%",
    padding: "30px",
    maxWidth: "340px",
    [theme.breakpoints.down(768)]: {
      padding: "20px",
      maxWidth: "220px",
    },
  },
}));
