import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductSlider.css";

const ProductSlider = ({ children }) => {
  const settings = {
    infinite: true,
    speed: 1000,
    slidesToShow: 7,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 750,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          dots: false,
        },
      },
      {
        breakpoint: 560,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="slider-container-wrapper">
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default ProductSlider;
