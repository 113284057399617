import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Typography,
  Button,
  Box,
} from "@material-ui/core";
import { FitScreen } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { addItemToCart } from "../../actions/cartAction";
import { useDispatch, useSelector } from "react-redux";
import "./Home.css";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: FitScreen,
    // margin: theme.spacing(1.5),
    backgroundColor: "white",
    color: "#000",
    cursor: "pointer",
    position: "relative",
    transition: "transform 0.3s, box-shadow 0.3s",
    "&:hover": {
      transform: "scale(1.05)",
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
    },
  },
  media: {
    width: "100%" /* Ensures image takes up full width of the container */,
    height: "auto" /* Adjust height based on width, maintaining aspect ratio */,
    minHeight:
      "130px" /* Minimum height to prevent the image from collapsing */,
    maxHeight: "auto" /* Allow height to adjust freely */,
    display: "block" /* Block-level element for proper layout behavior */,
    maxWidth: "100%" /* Prevent image from overflowing its container */,
    objectFit:
      "contain" /* Fit the image within its container, without cropping */,
    margin: "0 auto" /* Center the image horizontally in the container */,
    padding: "5px" /* Adds some padding around the image */,
    boxShadow:
      "0 4px 8px rgba(0, 0, 0, 0.1)" /* Optional subtle shadow for depth */,
    backgroundColor:
      "#f9f9f9" /* Adds a subtle background color for visual separation */,
    transition: "transform 0.3s ease" /* Smooth animation on hover */,
  },
  mediaCloaked: {
    width:
      "100%" /* Ensure the image takes up the full width of the container */,
    height: "auto" /* Height will auto-adjust based on width */,
    minHeight: "130px" /* Minimum height of the image */,
    maxHeight: "auto" /* Let the height adjust dynamically */,
    display: "block" /* Block-level element to allow full-width scaling */,
    objectFit:
      "cover" /* Ensures the image covers the square area without distortion */,
    aspectRatio: 1 / 1 /* Ensures the image stays square (1:1 aspect ratio) */,
  },
  button: {
    backgroundColor: "black",
    color: "white",
    borderRadius: 4,
    fontWeight: "bold",
    width: "100%",
    height: 22.5, // Halved the original height
    "&:hover": {
      backgroundColor: "#00aaa4",
      color: "black",
      fontWeight: "bold",
    },
  },
  oldPrice: {
    textDecoration: "line-through",
    fontWeight: "bold",
    fontSize: "0.6rem",
    color: "rgba(0, 0, 0, 0.6)",
    marginRight: theme.spacing(0.5), // Halved the original margin
  },
  finalPrice: {
    fontWeight: "bold",
    fontSize: "0.6rem", // Halved the original font size
    color: "#00aaa4",
  },
  description: {
    fontSize: "0.7rem", // Halved the original font size
    fontWeight: 500,
    marginTop: theme.spacing(0.5), // Halved the original margin
    marginBottom: theme.spacing(0.5), // Halved the original margin
    display: "-webkit-box",
    overflow: "hidden",
    textOverflow: "ellipsis",
    WebkitLineClamp: 3,
    WebkitBoxOrient: "vertical",
  },
  "out-of-stock": {
    position: "absolute",
    width: "100%",
    height: "100%",
    top: "0",
    right: "0",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
}));

const HomeCard = ({ product }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const { cloaked } = useSelector((state) => state.products);
  const isOutOfStock = Object.values(product?.Stock || {}).every(
    (quantity) => quantity <= 0
  );

  const truncateName = (name, maxLength = 12) => {
    if (name.length <= maxLength) return name; // If name is already short, return as is
    return name.slice(0, maxLength).trim() + "..."; // Cut at maxLength and add "..."
  };

  const nameTruncated = truncateName(product.name);
  // const addTocartHandler = (id) => {
  //   dispatch(addItemToCart(id , true))
  // }
  const addTocartHandler = (id, qty) => {
    dispatch(addItemToCart(id, qty));
  };
  return (
    <Card className={`${classes.root} home-card-wrapper`}>
      <Link
        onClick={() => {
          window.fbq("track", "ViewContent", {
            content_ids: product._id,
            content_type: "product",
          });
        }}
        className="productCard"
        to={`/product/${product._id}`}
        style={{ textDecoration: "none", color: "inherit" }}>
        <CardActionArea>
          <CardMedia
            className={`${
              cloaked ? classes.mediaCloaked : classes.media
            } home-card-image`}
            image={product?.images[0]?.url}
          />
          <CardContent>
            <Typography
              gutterBottom
              color="black"
              fontWeight="bold"
              style={{
                fontWeight: "700",
                fontSize: "0.7rem",
                color: "#000",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}>
              {nameTruncated}
            </Typography>
            <Box display="flex" alignItems="center">
              {/* <Rating
                name="rating"
                value={product.ratings}
                precision={0.1}
                readOnly
                size="small"
                style={{ color: "#00aaa4", marginRight: 4, fontWeight: "400" }}  // Adjusted margin and font weight
              />
              <Typography variant="body2" color="textSecondary">
                ({product.numOfReviews})
              </Typography> */}
            </Box>
            <Typography
              variant="body2"
              color="textSecondary"
              component="div"
              className={classes.description}>
              {product.description}
            </Typography>
            <Box display="flex" alignItems="center">
              {!!product.oldPrice && (
                <Typography variant="body1" className={classes.oldPrice}>
                  <span>{product.oldPrice}</span> <span> лв.</span>
                </Typography>
              )}
              <Typography variant="body1" className={classes.finalPrice}>
                <span>{product.price}</span> <span>лв.</span>
              </Typography>
            </Box>
          </CardContent>
        </CardActionArea>
        {isOutOfStock && <OutOfStockBadge />}
      </Link>
      {/* <Box display="flex" justifyContent="center" p={2}>
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => addTocartHandler(product._id)}
          disabled={product.Stock <= 0}
        >
          {product.Stock <= 0 ? 'Изчерпан' : 'Добави в количка'}
        </Button>
      </Box> */}
    </Card>
  );
};

const OutOfStockBadge = () => {
  const classes = useStyles();
  return (
    <div className={classes["out-of-stock"]}>
      <span className="outofstock">
        <CloseIcon fontSize="100" />
        <span> Out of Stock</span>
      </span>
    </div>
  );
};

export default HomeCard;
