import React from 'react';
import './Banner.css';

export function Banner({props}) {
  return (
    <a href={`${process.env.REACT_APP_FRONT_END_URL}/shop`} className="banner-link">
      <div style={{background: `url(${props.image}) no-repeat center center / cover`}} className="banner">
        <div className="banner-content">
          <h1>{props.title}</h1>
          <p>{props.subTitle}</p>
          <button>Магазин</button>
        </div>
      </div>
    </a>
  );
}

export default Banner;
