import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  "welcome-message": {
    color: "#fff",
  },

  "profile-info": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#fff",
    fontFamily: "serif",
  },
  "user-name": {
    fontSize: "24px",
    [theme.breakpoints.down(768)]: {
      fontSize: "18px",
    },
  },
  "user-email": {
    fontSize: "20px",
    [theme.breakpoints.down(768)]: {
      fontSize: "16px",
    },
  },
  avatar: {},
  "profile-menu": {
    display: "flex",
    flexDirection: "column",
    alignItems: "start",
    gap: "12px",
  },
  "menu-item": {
    fontSize: "20px",
    color: "#fff",
    display: "flex",
    alignItems: "end",
    gap: "8px",
    cursor: "pointer",
    transition: "all 0.2s",
    "&:hover": {
      color: "#aaa",
      transform: "scale(1.1)",
    },
  },
  "menu-icon": {
    fontSize: "30px",
    color: "inherit",
  },

  active: {
    color: "#aaa",
  },
}));
