import React, { useRef, useState } from 'react';
import './ImageSlider.css';
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io";
import { IoClose } from "react-icons/io5";

const ImageSlider = ({ images, onClose }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isZoomed, setIsZoomed] = useState(false);
  const lastTapRef = useRef(0);
  const imageRef = useRef(null);

  const handleZoom = (x, y) => {
    const image = imageRef.current;
    const rect = image.getBoundingClientRect();

    // Calculate the position where the user clicked/tapped
    const offsetX = x - rect.left;
    const offsetY = y - rect.top;

    // Set the transform origin based on the click/tap position
    image.style.transformOrigin = `${offsetX}px ${offsetY}px`;

    // Toggle the zoom state
    setIsZoomed(!isZoomed);
  };

  const handleDoubleClick = (event) => {
    handleZoom(event.clientX, event.clientY);
  };

  const handleTouchEnd = (event) => {
    const currentTime = new Date().getTime();
    const tapLength = currentTime - lastTapRef.current;

    if (tapLength < 300 && tapLength > 0) {
      const touch = event.changedTouches[0];
      handleZoom(touch.clientX, touch.clientY);
    }

    lastTapRef.current = currentTime;
  };
  
  const prevSlide = () => {
    const newIndex = (currentIndex === 0) ? images.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const newIndex = (currentIndex === images.length - 1) ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className='slider-overlay'>
      <div className="slider-overlay" onClick={onClose}>
      </div>
      <div className="slider">
        <button className="close-button" onClick={onClose}><IoClose /></button>
        <div className="slider__image-container">
        <img
          src={images[currentIndex].url}
          alt={`Slide ${currentIndex + 1}`}
          onDoubleClick={handleDoubleClick}
          onTouchEnd={handleTouchEnd}
          ref={imageRef}
          className={`slider__image ${isZoomed ? 'zoomed' : ''}`}
        />
        </div>
        <div className="slider__nav">
          <button className="slider__nav-btn" onClick={prevSlide}><IoIosArrowDropleftCircle /></button>
          <span>{currentIndex + 1} / {images.length}</span>
          <button className="slider__nav-btn" onClick={nextSlide}><IoIosArrowDroprightCircle /></button>
        </div>        
      </div>
    </div>
  );
};

export default ImageSlider;
