import React, { useState } from "react";
import Carousel from "react-material-ui-carousel";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { Link } from "react-router-dom";
import { slides } from "../../constants/homeConstants";
import "./HeroSlider.css";

export default function HeroSlider() {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % slides?.length);
  };

  const handleBack = () => {
    setActiveStep(
      (prevActiveStep) => (prevActiveStep - 1 + slides?.length) % slides?.length
    );
  };

  return (
    <>
      <div className="carousel-wrapper">
        <Carousel
          autoPlay={true}
          navButtonsAlwaysVisible={false}
          indicators={false}
          animation="slide"
          interval={50000}
          timeout={500}
          cycleNavigation={true}
          navButtonsProps={{
            style: {
              backgroundColor: "transparent",
              borderRadius: 100,
              padding: 50,
              margin: 0,
              height: "100%",
              width: "100%",
            },
          }}
          prevButton={
            <Button
              onClick={handleBack}
              startIcon={
                <ArrowBackIosIcon color="white" fontSize="50" />
              }></Button>
          }
          nextButton={
            <Button
              onClick={handleNext}
              endIcon={
                <ArrowForwardIosIcon color="white" fontSize="50" />
              }></Button>
          }
          fullHeightHover={false}
          className={"slide"}
          index={activeStep}
          onChangeIndex={setActiveStep}>
          {slides &&
            slides.map(
              ({ image, href, saleText, quote, productText }, index) => (
                <div key={`hero-slider-${index}`} className="slide">
                  <img src={image} alt="slider" className="slide-image" />
                  <div className="slide-overlay"></div>
                  <div className="slide-content">
                    <h2 className="sale-text">{saleText}</h2>
                    <h3 className="quote">{quote}</h3>
                    <Link to={`/shop/${href}`}>
                      <Button className="product-button">{productText}</Button>
                    </Link>
                  </div>
                </div>
              )
            )}
        </Carousel>
      </div>
    </>
  );
}
