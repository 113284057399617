import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Typography, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { RESET_CART } from "../../constants/cartConstant";

const useStyles = makeStyles((theme) => ({
  orderSuccess: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "144px 0px 220px",
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down(768)]: {
      padding: "100px 20px 40px",
    },
  },
  successIcon: {
    fontSize: "8rem",
    color: theme.palette.success.main,
    marginBottom: theme.spacing(4),
  },
  successText: {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
    fontSize: "2rem",
    color: theme.palette.text.primary,
  },
  link: {
    textDecoration: "none",
  },
  viewOrdersButton: {
    marginTop: theme.spacing(4),
    padding: theme.spacing(2, 4),
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    borderRadius: theme.spacing(4),
    textTransform: "uppercase",
    letterSpacing: 2,
    fontWeight: "bold",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: "#9caccc",
    },
  },
}));

function OrderSuccess() {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: RESET_CART });
    localStorage.setItem("cartItem", JSON.stringify([]));
  }, [dispatch]);

  return (
    <div className={classes.orderSuccess}>
      <CheckCircleIcon className={classes.successIcon} />

      <Typography variant="h4" className={classes.successText}>
        Поръчката Ви е приета успешно!
        <br />
        Благодарим ви!
      </Typography>
      <h5 style={{ color: "black" }}>
        <br />
        <b>
          Ако не откривате имейла с проръчката Ви, проверете папката със спам.
        </b>
      </h5>
      <Link to="/orders" className={classes.link}>
        <Button variant="contained" className={classes.viewOrdersButton}>
          Преглед на поръчките
        </Button>
      </Link>
    </div>
  );
}

export default OrderSuccess;
