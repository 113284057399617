import { Skeleton } from "@mui/material";
import React from "react";

const arr = Array.from({ length: 15 }, (_, i) => i + 1);

export const ImageGridSkeleton = ({ className }) => {
  return (
    <div className={`${className}`}>
      {arr.map((_, i) => (
        <Skeleton
          key={`image-skeleton-${i}`}
          variant="rounded"
          width="100%"
          height={240}
          sx={{ bgcolor: "grey.700" }}
        />
      ))}
    </div>
  );
};
