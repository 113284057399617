import React, { useState } from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import { useStyles } from "./CheckboxInputStyles";

const CheckboxInput = ({
  register,
  name,
  label,
  onChangeFn,
  value,
  checked,
  errors,
  type = "text",
  ...props
}) => {
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        {...register(name)}
        control={
          <Checkbox
            className={classes.checkbox_input}
            checked={checked}
            onChange={onChangeFn}
            value={value}
          />
        }
        label={label}
        {...props}
      />
      {/* {errors[name] && (
        <span className={classes["error-message"]}>{errors[name].message}</span>
      )} */}
    </>
  );
};

export default CheckboxInput;
