import React, { useEffect, useState } from "react";
import "./Home.css";
import ProductCard from "./ProductCard";
import MataData from "../layouts/MataData/MataData";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layouts/loader/Loader";
import { useAlert } from "react-alert";
import HeroSlider from "./HeroSilder";
import FeaturedSlider from "./FeatureSlider";
import ProductAnimation from "./CardAnimated";
import CardSection from "./CardSection";
import CardStack from "./CardStack/CardStack";
import Banner from "./BannerStack/Banner";
import ProductGrid from "./BannerStack/ProductGrid";
import { mainBanner, shuffleArray } from "../../constants/homeConstants";
import SliderComponent from "./BannerStack/SliderComponent";
import VideoComponent from "./VideoStack/VideoComponent";
import HomeCard from "./HomeCard";
import {
  clearErrors,
  getLatestProducts,
  getShuffledProducts,
  getProduct,
} from "../../actions/productAction";
import ProductSlider from "./BannerStack/ProductSlider";

function Home() {
  // we provided all parameter for react-alert at index.js
  const alert = useAlert();
  const dispatch = useDispatch();
  const [badConnection, setBadConnection] = useState(false);
  const { loading, error, latestProducts, shuffledProducts } = useSelector(
    (state) => state.products
  );

  useEffect(() => {
    dispatch(getLatestProducts());
    dispatch(getShuffledProducts());
  }, [dispatch]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      setBadConnection(true);
      dispatch(clearErrors);
    }
  }, [dispatch, error, alert]);

  const showLoader = loading || (!latestProducts && !shuffledProducts);

  return (
    <main className="home-main">
      <MataData title="Sneaker Brigade" />
      <div className="Home_Page">
        <div className="heroSlider_Home">
          <HeroSlider />
        </div>
        <h1 className="t-h1">Нови чифтове</h1>
        <div className="newArrivals_Grid">
          {latestProducts?.length > 0 ? (
            latestProducts?.map((product, i) => (
              <HomeCard key={`home-card-${i}`} product={product} />
            ))
          ) : (
            <p>Нови чифтове не са намерени.</p>
          )}
        </div>
        <div className="banner-grid-wrapper">
          <Banner props={mainBanner} />
          <ProductGrid />
        </div>

        <h2 className="trending_heading">Популярни продукти</h2>
        <ProductSlider>
          {shuffledProducts?.length > 0 ? (
            shuffledProducts?.map((product, i) => (
              <div
                key={`shuffle-product-${i}-${product._id}`}
                className="slider-card-wrapper">
                <HomeCard product={product} />
              </div>
            ))
          ) : (
            <p>No products available.</p>
          )}
        </ProductSlider>
        <SliderComponent />
      </div>
      {showLoader && <Loader badConnection={badConnection} />}
    </main>
  );
}

export default Home;
