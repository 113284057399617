/* eslint-disable no-undef */
import React, { useEffect, useState } from "react";
import Loader from "../layouts/loader/Loader";
import "./Product.css";
import { useRouteMatch } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import useActive from "../hook/useActive";
import { clearErrors, getProductDetails } from "../../actions/productAction";
import { PRODUCT_DETAILS_RESET } from "../../constants/productsConstatns";
import { addItemToCart } from "../../actions/cartAction";
import { Button, MenuItem, Select } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { FaTruckFast } from "react-icons/fa6";
import { GiWallet } from "react-icons/gi";
import { LuPackageSearch } from "react-icons/lu";
import ImageSlider from "./ImageSlider";

const Product = () => {
  const match = useRouteMatch();
  const dispatch = useDispatch();
  const alert = useAlert();
  const [selectedSize, setSelectedSize] = useState(0);
  // const [quantity, setQuantity] = useState(1);
  const [previewImg, setPreviewImg] = useState(null);
  const { handleActive, activeClass } = useActive(0);
  const [showSlider, setShowSlider] = useState(false);
  // const [dropdownValue, setDropdownValue] = useState("");
  // const isMobileOrTablet = false;
  const { product, loading, error, success } = useSelector(
    (state) => state.productDetails
  );

  useEffect(() => {
    const fontTag = document.querySelector("font");

    if (!!fontTag) {
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    if (product && success) {
      const firstAvailableSize = Object.entries(product.Stock || {}).find(
        ([size, quantity]) => quantity > 0
      )?.[0];
      setSelectedSize(firstAvailableSize);
    }
  }, [product, success]);

  useEffect(() => {
    dispatch(getProductDetails(match.params.id));
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success && product) {
      setPreviewImg(product.images?.[0].url);
      handleActive(0);
      dispatch({ type: PRODUCT_DETAILS_RESET });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, error, alert, success, match.params.id]);

  useEffect(() => {
    setPreviewImg(product.images?.[0].url);
  }, [product.images]);

  // handling Add-to-cart
  const handleAddItem = () => {
    window.fbq("track", "AddToCart", {
      content_ids: [product._id],
      content_type: "product",
    });

    dispatch(addItemToCart(match.params.id, selectedSize, true));
    alert.success("Продуктът е добавен в количката.");
  };

  const toggleModal = () => {
    setShowSlider((prev) => !prev);
  };

  const hasQuantity =
    Object.keys(product).length > 0
      ? Object.values(product?.Stock).some((qty) => qty > 0)
      : true;

  return (
    <div className="product-container">
      <div className="image-gallery">
        {showSlider && (
          <ImageSlider onClose={toggleModal} images={product?.images} />
        )}
        <div onClick={toggleModal} className="main-image">
          <img src={previewImg} alt="Main" />
        </div>
        <div className="thumbnails">
          {product?.images?.map((image, index) => (
            <img
              src={image.url}
              alt={`Thumbnail ${index + 1}`}
              key={`product-img-${index}`}
              onClick={() => setPreviewImg(image.url)}
            />
          ))}
        </div>
      </div>
      <div className="product-details">
        <h1>{product?.name}</h1>
        <p style={{ color: "black", fontWeight: "normal" }}>
          {product?.category}
        </p>
        <p>🚛Бърза доставка 📦 Опция преглед и тест</p>
        {/* <p>{product?.info && product.info}</p> */}
        <div className="price-wrapper">
          {!!product.oldPrice && (
            <h3 className="price strike-price">
              <span>{product?.oldPrice}</span> <span>лв.</span>
            </h3>
          )}
          <h3 className="price">
            <span>{product?.price}</span> <span>лв.</span>
          </h3>
        </div>
        <div className="badge">
          {hasQuantity ? (
            <span className="instock">
              <DoneIcon fontSize="50" /> <span>В наличност</span>
            </span>
          ) : (
            <span className="outofstock">
              <CloseIcon fontSize="100" />
              <span>Изчерпан</span>
            </span>
          )}
        </div>
        <div className="product-description">
          <p>{product?.description}</p>

          <div>
            <h3>Избери размер</h3>
            <div className="sizes">
              {Object.entries(product?.Stock || {}).map(
                ([size, quantity], index) => (
                  <Button
                    className={`${
                      selectedSize === size
                        ? "size-button-selected"
                        : "size-button"
                    }`}
                    value={size}
                    onClick={() => setSelectedSize(size)}
                    disabled={quantity <= 0}
                    key={`choose-size-btn-${index}`}>
                    {size}
                  </Button>
                )
              )}
            </div>
          </div>
          <div className="button-container">
            <Button
              variant="contained"
              className="prod_details_addtocart_btn"
              onClick={handleAddItem}
              disabled={
                product.Stock &&
                product?.Stock[selectedSize] &&
                product.Stock[selectedSize] > 0
                  ? false
                  : true
              }>
              {product.Stock &&
              product?.Stock[selectedSize] &&
              product.Stock[selectedSize] > 0
                ? "Добави в количката"
                : "Изчерпан"}
            </Button>
          </div>
        </div>

        <div className="shipping-info">
          <p>
            <FaTruckFast size={20} /> Бърза доставка
          </p>
          <p>
            <LuPackageSearch size={20} /> Ще видите нашите опции за доставка при
            финализиране на поръчката
          </p>
          <p>
            <GiWallet size={20} /> Наложен платеж
          </p>
          <Button
            style={{ marginTop: "15px" }}
            className="see_more_btn"
            href="/shop">
            Виж други продукти
          </Button>
        </div>
      </div>
      {loading && <Loader />}
    </div>
  );
};

export default Product;
