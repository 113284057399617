import React, { useRef } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useStyles } from "./FilterAccordionStyle";

const FilterAccordion = ({ children, className, label, open, setOpen }) => {
  const classes = useStyles();
  const ref = useRef(null);

  return (
    <div className={className} ref={ref}>
      <Accordion
        expanded={open}
        className={classes["filter-accordion-wrapper"]}>
        <AccordionSummary
          onClick={() => setOpen(!open)}
          expandIcon={
            <FilterListIcon className={classes["filter-accordion-icon"]} />
          }
          aria-controls="panel1-content"
          id="panel1-header">
          {label}
        </AccordionSummary>
        <AccordionDetails className={classes["filter-accordion-details"]}>
          {children}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default FilterAccordion;
